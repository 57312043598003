import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Hero from 'organisms/hero'
import Button from 'atoms/button'
import Layout from 'layouts/index-ppc'
import PardotForm from 'components/forms/pardot'
import HubSpotForm from 'components/forms/HubSpotForm'
import LandingPageSection from 'components/sections/landing-page-section'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import MetaDataImage from '../images/meta-image.png'

const PpcTemplate = ({ data }) => {
  const hubspotFormReference = data.page.hubspotFormReference || {};
  const { portalid, formid, campaignId } = hubspotFormReference;

  return (
    <Layout>
      <div className="c-ppc-detail">
        <Metadata
          title={data.page.metadataTitle || data.page.title}
          description={data.page.metadataDescription}
          keywords={data.page.metadataKeywords?.join(`, `)}
          image={MetaDataImage}
        />
        <Hero
          className="c-hero__resource o-hero--custom"
          curveBottom
          gradientSenery
          title={data.page.heroTitle}
        >
          <Hero.Description>
            {data.page.heroDescription && (
              <DangerousHTML>
                {data.page.heroDescription.html.text}
              </DangerousHTML>
            )}
          </Hero.Description>
          <Hero.Image>
            {data.page.hubspotFormReference &&
            (data.page?.pardotFormReferencePlacement === 'In Hero' ? (
              <div class="c-hero__pardot">
                <h3 className="c-hero__pardot--heading">
                  Please provide your information below
                </h3>
                <div id="hubspot-form-container" className="hubspot-form-container">
                  <HubSpotForm portalId={portalid} formId={formid} campaignId={campaignId || ''} />
                </div>
                <p>
                  For translators 
                  interested in translating for Smartling,{' '}
                  <a href="/translator_information">Apply here</a>.
                </p>
                <p>
                  By registering, you agree to the processing of your personal
                  data by Smartling as described in our{' '}
                  <a href="/privacy">Privacy Policy</a>.
                </p>
              </div>
            ) : (
              ``
            ))}
            
            {data.page.gatsbyImageData && data.page?.pardotFormReference && data.page?.pardotFormReferencePlacement !== 'In Hero'  ? (
              <GatsbyImage
                image={data.page.heroImage.gatsbyImageData}
                alt={data.page.heroImage.title}
                objectFit="contain"
                // title={heroDescription.description}
              />
            ) : (
              <img
                loading="lazy"
                src={data.page.heroImage?.file.url}
                alt={data.page.heroImage?.title}
              />
            )}
          </Hero.Image>
          <Hero.Buttons>
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn More`}
                  </Button>
                )
              })}
          </Hero.Buttons>
        </Hero>
        {data.page.pageSections &&
          data.page.pageSections.map((section) => (
            <LandingPageSection {...section} />
          ))}

        {data.page.hubspotFormReference &&
          (data.page?.pardotFormReferencePlacement === 'Below Body' ? (
            <div
              className={`l-wrap ${
                data.page?.pardotFormReference.name ===
                'Email Capture - Viewed How Lyft Scaled Content for 8 Languages PPC Webinar'
                  ? 'l-container--s'
                  : 'l-container--xs'
              }`}
            >
              <div id="hubspot-form-container" className="hubspot-form-container">
                <HubSpotForm portalId={portalid} formId={formid} campaignId={campaignId || ''} />
              </div>
            </div>
          ) : (
            ``
          ))}
      </div>
    </Layout>
  )
}

export default PpcTemplate

export const pageQuery = graphql`
  query PpcTemplateQuery($slug: String!) {
    page: contentfulPpc(slug: { eq: $slug }) {
      name
      slug
      heroTitle
      heroDescription {
        html: childMarkdownRemark {
          text: html
        }
      }
      heroImage {
        # ...ContentfulImageFragment
        title
        file {
          url
        }
        gatsbyImageData(
          width: 610
          formats: [WEBP, AUTO, PNG]
          placeholder: BLURRED
          height: 343
        )
      }
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      pageSections {
        ...ContentfulLandingPageSectionRowFragment
        ...ContentfulLandingPageSectionGridFragment              
        ...ContentfulLandingPageSectionDropdownsFragment              
        ... on ContentfulLogoCarousel {
          internal {
            type
          }
          title
          sliderItems {
            image {
              file {
                url
              }
              description
            }
          }
        }
      }
      hubspotFormReference {
        portalid
        formid
        campaignId
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
    }
  }
`
