import React from 'react';
import { graphql } from 'gatsby';
import Metadata from '../layouts/metadata';
import MetaDataImage from '../images/meta-image.png';
import { DangerousHTML } from 'atoms/contentful/html';
import Button from 'atoms/button';
import PardotForm from 'components/forms/pardot';
import Hero from 'organisms/hero';
import LandingPageSection from 'components/sections/landing-page-section';
import DefaultLayout from 'layouts/index-paid-ads';
import CustomLayout from 'layouts';
import HubSpotForm from 'components/forms/HubSpotForm'

const PaidAdsTemplate = ({ data }) => {
  const Layout = data.page.heroTitle === 'Measuring Translation Quality With Smartling'
                 ? CustomLayout
                 : DefaultLayout;
  
  const hubspotFormReference = data.page.hubspotFormReference || {};
  const { portalid, formid, campaignId } = hubspotFormReference;

  return (
    <Layout>
      <Metadata
        title={data.page.metadataTitle || data.page.title}
        description={data.page.metadataDescription}
        keywords={data.page.metadataKeywords?.join(`, `)}
        image={MetaDataImage}
      />

      <div className="l-landing-page-pa">
        <Hero
          className="c-hero__resource o-hero--custom u-background--primary"
          curveBottom
          title={data.page.heroTitle}
        >
          <Hero.Description>
            {data.page.heroDescription && (
              <DangerousHTML>
                {data.page.heroDescription.html.text}
              </DangerousHTML>
            )}
          </Hero.Description>
          <Hero.Image>
            {data.page.hubspotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'In Hero' ? (
                <div className="c-hero__pardot">
                  <h3 className="c-hero__pardot--heading">
                    Please provide your information below
                  </h3>
                  <div id="hubspot-form-container" className="hubspot-form-container">
                    <HubSpotForm portalId={portalid} formId={formid} campaignId={campaignId || ''} />
                  </div>
                  <p>
                    For translators 
                    interested in translating for Smartling,{' '}
                    <a href="/translator_information">Apply here</a>.
                  </p>
                  <p>
                    By registering, you agree to the processing of your personal
                    data by Smartling as described in our{' '}
                    <a href="/privacy">Privacy Policy</a>.
                  </p>
                </div>
              ) : (
                ``
              ))}
          </Hero.Image>
          <Hero.Buttons>
            <div className="c-hero__badges">
              <a 
                title="Smartling is a leader in Enterprise Translation Management on G2"
                href="https://www.g2.com/products/smartling/reviews"
              >
                <img 
                  alt="Smartling is a leader in Enterprise Translation Management on G2"
                  src="https://images.g2crowd.com/uploads/report_medal/image/1004062/medal.svg" 
                />
              </a>
              <a 
                title="Smartling is a leader in Mid-Market Localization on G2"
                href="https://www.g2.com/products/smartling/reviews"
              >
                <img 
                  alt="Smartling is a leader in Mid-Market Localization on G2"
                  src="https://images.g2crowd.com/uploads/report_medal/image/1004061/medal.svg" 
                />
              </a>
              <a 
                title="Smartling is a leader in Enterprise Localization on G2"
                href="https://www.g2.com/products/smartling/reviews"
              >
                <img 
                  alt="Smartling is a leader in Enterprise Localization on G2"
                  src="https://images.g2crowd.com/uploads/report_medal/image/1004086/medal.svg" 
                />
              </a>
              <a 
                title="Smartling is a leader in Enterprise Localization on G2"
                href="https://www.g2.com/products/smartling/reviews"
              >
                <img 
                  alt="Smartling is a leader in Enterprise Localization on G2"
                  src="https://images.g2crowd.com/uploads/report_medal/image/1004094/medal.svg" 
                />
              </a>
              <a 
                title="Smartling is a leader in Mid-Market Localization on G2"
                href="https://www.g2.com/products/smartling/reviews"
              >
                <img 
                  alt="Smartling is a leader in Mid-Market Localization on G2"
                  src="https://images.g2crowd.com/uploads/report_medal/image/1004073/medal.svg" 
                />
              </a>
              <a 
                title="Smartling is a leader in Mid-Market Americas Translation Management on G2"
                href="https://www.g2.com/products/smartling/reviews"
              >
                <img 
                  alt="Smartling is a leader in Mid-Market Americas Translation Management on G2"
                  src="https://images.g2crowd.com/uploads/report_medal/image/1004189/medal.svg" 
                />
              </a>
            </div>
          </Hero.Buttons>
        </Hero>
        {data.page.pageSections &&
        data.page.pageSections.map((section, index) => (
          <LandingPageSection key={index} {...section} />
        ))}
        {data.page.hubspotFormReference &&
        (data.page?.pardotFormReferencePlacement === 'Below Body' ||
        data.page?.pardotFormReferencePlacement === 'Hero and Below Body') && (
          <div
            className={`l-wrap ${
              data.page.hubspotFormReference.name ===
              'Email Capture - Viewed How Lyft Scaled Content for 8 Languages PPC Webinar'
                ? 'l-container--s'
                : 'l-container--xs'
            }`}
          >
            <div id="hubspot-form-container" className="hubspot-form-container">
              <HubSpotForm portalId={portalid} formId={formid} campaignId={campaignId || ''} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PaidAdsTemplate;

export const pageQuery = graphql`
  query PaidAdsTemplateQuery($slug: String!) {
    page: contentfulLandingPageTemplatePaidAds(slug: { eq: $slug }) {
      title
      slug
      heroTitle
      heroDescription {
        html: childMarkdownRemark {
          text: html
        }
      }
      pageSections {
        ...ContentfulLandingPageSectionRowFragment
        ...ContentfulLandingPageSectionGridFragment
        ...ContentfulLandingPageSectionDropdownsFragment
      }
      hubspotFormReference {
        portalid
        formid
        campaignId
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
    }
  }
`

