import React from 'react'
import PropTypes from '../prop-types'

import Header from './header'
import Footer from './footer'
import Alert from 'organisms/alert'
import '../scss/main.scss'

const Layout = ({ children }) => (
  <React.Fragment>
    <Alert />
    <main className="l-main">
      <Header />
      <div className="l-content">{children}</div>
      <Footer />
    </main>
  </React.Fragment>
)

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout
