import React from 'react'
import PropTypes from '../prop-types'
import Logo from 'atoms/logo'
import * as Icons from 'atoms/icons'
import Grid from 'atoms/grid'
import buttonClickSmartling from '../images/illustrations/button-click-smartling.svg'
import PardotResponsive from '../02-organisms/pardot-responsive'
import imageStars from '../images/g2-new.png'
import imageStarsWebp from '../images/g2-new.webp'
import Button from 'atoms/button'
import HubSpotFormNewsletter from 'components/forms/HubSpotFormNewsletter';

const Footer = () => (
  <React.Fragment>
    <div className="c-footer__wrapper">
      <section className="c-footer--cta u-gradient--senery-alt u-padding--section u-space--quint--top l-wrap">
        <Grid twoUp className="l-grid--align-top">
          <Grid.Item className="u-spacing">
            <div className="u-spacing">
              <h3 className="c-block__title o-section-title u-font--xl-alt o-section-title--heavy ">
                The Fastest Way To Publish Translations.
              </h3>
              <p className="u-font--m c-cta__description">
                Personalize your content into any language with Smartling’s
                full-service translation solution.
              </p>
              <div className="u-space--bottom u-space--double--top o-button--group">
                <a href="/meeting-request/" role="button" class="o-button">
                  Book a Meeting
                </a>
                <a href="/demo/" role="button" class="o-button ">
                  Pre-Recorded Demo
                </a>
              </div>
            </div>
          </Grid.Item>
          <Grid.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <img
                loading="lazy"
                src={buttonClickSmartling}
                alt="Click Smartling"
                id="buttonClickSmartling"
              />
            </div>
          </Grid.Item>
        </Grid>
      </section>
      <footer className="c-footer l-wrap l-container--xl" role="contentinfo">
        <div className="c-footer__content">
          <Nav>
            <Nav.Group title="Get Started">
              <Nav.Item to="https://dashboard.smartling.com/" title="Login" />
              <Nav.Item to="/plans" title="Plans" />
              <Nav.Item to="/meeting-request/" title="Get a Demo" />
            </Nav.Group>
            <Nav.Group title="Company">
              <Nav.Item to="/about-us" title="About Us" />
              <Nav.Item to="/careers" title="Careers" />
              <Nav.Item to="/become-a-translator" title="Become a Translator" />
              <Nav.Item to="/partner-ecosystem" title="Partners" />
              <Nav.Item to="/resources/press-release" title="Press" />
            </Nav.Group>
            <Nav.Group title="Documents">
              <Nav.Item
                to="/sample-translation-rfp"
                title="Sample Translation RFP"
              />

              <Nav.Item
                to="/excellence-every-touchpoint"
                title="Excellence at Every Touchpoint"
              />
              <Nav.Item to="/compare" title="Smartling vs. Competitors" />
              <Nav.Item to="/security" title="Security" />
            </Nav.Group>
            <Nav.Group title="Support">
              <Nav.Item to="/contact-us" title="Contact Us" />
              <Nav.Item to="/resources" title="Resources" />
              <Nav.Item to="//status.smartling.com" title="Status" />
              <Nav.Item
                to="//help.smartling.com/hc/en-us"
                title="Help Center"
              />
            </Nav.Group>
          </Nav>
          <div className="c-footer__copyright u-space--bottom">
            <div className="u-spacing--half c-footer__quick-links">
              <div className="c-footer__social">
                <div className="c-social">
                  <a
                    href="https://twitter.com/smartling"
                    aria-label="Social Icon Twitter"
                    className="c-social__link c-social__link-twitter u-space--half--left u-space--half--right"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.Twitter s />
                  </a>
                  <a
                    href="https://www.facebook.com/smartlinginc"
                    aria-label="Social Icon Facebook"
                    className="c-social__link c-social__link-facebook u-space--half--left u-space--half--right"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.Facebook s />
                  </a>
                  <a
                    href="https://www.youtube.com/SmartlingInc"
                    aria-label="Social Icon YouTube"
                    className="c-social__link c-social__link-youtube u-space--half--left u-space--half--right"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.Youtube s />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/smartling"
                    aria-label="Social Icon LinkedIn"
                    className="c-social__link c-social__link-linkedin u-space--half--left u-space--half--right"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.Linkedin s />
                  </a>
                </div>
              </div>
              <div className="c-footer_legal u-flex u-spacing--left u-font--xs">
                <a href="/privacy">
                  <u>Privacy</u>
                </a>
                <a href="/legal">
                  <u>Legal</u>
                </a>
                <a href="/security">
                  <u>Security</u>
                </a>
              </div>
            </div>
            <div className="c-footer__branding u-spacing">
              <div className="c-logo">
                <a
                  href="/"
                  className="c-logo__link"
                  aria-label="Smartling Logo"
                >
                  <Logo />
                </a>
              </div>
              <p className="u-font--xs">
                The leading translation management platform to localize your
                content across devices and platforms.
              </p>
              <p className="u-font--xs u-space--zero">
                © {new Date().getFullYear()} by Smartling, Inc.
              </p>
            </div>
          </div>
        </div>
        <div className="c-footer__newsletter" id="footer-newsletter">
          <h5 class="u-color--primary">Sign up for our newsletter:</h5>
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a name="o-pardot-form" class="u-block o-pardot-form">
              <div className="o-pardot-form o-pardot-form__footer">
                <div id="hubspot-form-container-2" className="hubspot-form-container">
                  <HubSpotFormNewsletter />
                </div>
              </div>
            </a>
          </div>
        </div>
      </footer>
    </div>
  </React.Fragment>
)

export default Footer

const Nav = ({ children }) => (
  <nav className="c-footer-nav">
    <ul className="c-footer-nav__list">{children}</ul>
  </nav>
)

Nav.propTypes = {
  children: PropTypes.children.isRequired,
}

Nav.Group = ({ title, children }) => (
  <li className="c-footer-nav__item u-spacing">
    <h5 className="u-color--primary">{title}</h5>
    <ul className="c-footer-subnav__list u-spacing--half">{children}</ul>
  </li>
)

Nav.Group.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.children.isRequired,
}

Nav.Item = ({ to, title }) => (
  <li className="c-footer-subnav__item">
    <a href={to} className="c-footer-subnav__link">
      {title}
    </a>
  </li>
)

Nav.Item.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
