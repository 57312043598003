import React from 'react'
import Metadata from '../../layouts/metadata'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Section from 'atoms/section'
import AccordionTable from 'organisms/AccordionTable'
import { Link } from 'gatsby'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'

import Layout from 'layouts'

// Images
import heroImage from '../../images/translate/hero-translate.png'
import eliminateImage from '../../images/translate/eliminate-manual-work.jpg'
import improveTranslationImage from '../../images/translate/improve-translation.jpg'
import scalableImage from '../../images/translate/scalable-translation.jpg'

import caseYext from '../../images/translate/case-yext.png'
import caseIHG from '../../images/translate/case-ihg.png'
import caseClassPass from '../../images/translate/case-classpass.jpg'

import logoYext from '../../images/yext-logo.svg'

import logoDoordash from '../../images/translate/logo-doordash.svg'
import logoIHG from '../../images/translate/logo-ihg.svg'
import logoReddit from '../../images/translate/logo-reddit.svg'
import logoPinterest from '../../images/translate/logo-pinterest.svg'
import logoProgressive from '../../images/translate/logo-progressive.svg'
import logoWalgreens from '../../images/translate/logo-walgreens.svg'

import imageProduct from '../../images/illustrations/Teams_Product.png'
import imageCost from '../../images/illustrations/cost-estimates.svg'
import imageUi from '../../images/jobs-ui-noani.gif'
import imageAutomation from '../../images/ui/jobs-automation.gif'
import imageChecks from '../../images/ui/quality-checks.gif'
import sectionImage3 from '../../images/gabi-dance-3-bw_720.jpg'
import translationImage from '../../images/illustrations/translation-cake.png'
import logoBmj from '../../images/logo-bmj.png'
import iconCog from '../../images/icons/icon_cog-light-ring.svg'
import iconBlog from '../../images/icons/icon_blog-light-ring.svg'
import iconAnalytics from '../../images/icons/icon_analytics-light-ring.svg'
import iconBinoculars from '../../images/illustrations/binoculars-icon.png'
import iconContent from '../../images/icons/icon_content-whiteline.svg'
import iconDocuments from '../../images/icons/icon_documents-whiteline.svg'
import iconTranslation from '../../images/icons/icon_translation-cloud-whiteline.svg'
import iconFuzzymatch from '../../images/icons/icon_fuzzymatch-whiteline.svg'
import iconDiamond from '../../images/icons/icon_diamond-whiteline.svg'
import iconCog2 from '../../images/icons/icon_cog-whiteline.svg'
import iconContext from '../../images/icons/icon_visual-context-dark-ring.svg'
import iconClick from '../../images/icons/icon_click-dark-ring.svg'
import iconPi from '../../images/icons/icon_pi-dark-ring.svg'
import iconGoals from '../../images/icons/icon_goals-light-ring.svg'
import iconGlove from '../../images/icons/icon_whiteglove-light-ring.svg'

const accordionData = [
  {
    title: "What is a translation management system?",
    body: {
      html: {
        text: "<div class='c-table-accordion__body u-spacing o-text'><p>A translation management system, or translation management software, (TMS) is a centralized hub for managing the localization process and translation of language assets.</p><p>A TMS helps you streamline translation project management and improve content creation workflows, organize and collaborate on translated content effectively.</p></div>"
      }
    }
  },
  {
    title: "Should you use a translation management system?",
    body: {
      html: {
        text: "<div class='c-table-accordion__body u-spacing o-text'><p>Without the use of a translation management system, translation management typically involves breaking content up into strings (chunks of text) and collaborating with professional translation services manually over email and spreadsheets.</p><p>This can be okay for small projects.</p><p>If the scale of your project has outgrown the spreadsheet method and you’re looking for a way to ensure all content translation happens in-context, then a TMS is likely a good investment.</p></div>"
      }
    }
  },
  {
    title: "Do Smartling translation management tools integrate with my favorite CMS (content management system) or tool?",
    body: {
      html: {
        text: "<div class='c-table-accordion__body u-spacing o-text'><p>Yes! There are three ways you can connect Smartling to your existing software stack for user-friendly and collaborative translation:</p><ol><li><a href='/software/global-delivery-network/'>The Global Delivery Network</a> (aka web translation proxy)</li><li>Proprietary <a href='/software/'>integrations</a> with tools like Contentful, WordPress, and Drupal</li><li>A custom <a href='/software/api/'>API solution</a></li></ol></div>"
      }
    }
  }
];

class ManagePage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Translation Management Platform for Every Language | Smartling"
          description="Smartling's cloud translation management platform allows you to manage your translation process more effectively, creating more impactful customer experiences."
          keywords="Translation management system, Translation management, Translation platform"
        />
        <div className="l-page-translation">
          <Hero
            curveLeftReverse
            backgroundPrimary
            title="Manage multilingual content at scale"
          >
            <Hero.Description>
              <div className="u-spacing--and-half">
                <div className="c-hero__cta-text">
                Managing a localization program or project can be complex, but our cloud-based translation management platform is built to improve efficiency, drive consistency, and scale for growth.
                </div>
              </div>
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                src={heroImage}
                alt="Global Delivery Network"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a meeting</Button>
            </Hero.Buttons>
          </Hero>

          <article className="l-article u-spacing--sections">
            <section className="o-section--inner l-wrap l-container--l c-product-intro-section u-align--center u-space--double--top">
              <div className="u-spacing--triple">
                <h3 className="o-section-title o-section-title--heavy">
                  Smartling is trusted by
                </h3>
                <div className="c-logo-bar">
                  <div className="c-logo-bar__logo">
                    <img 
                      alt="Doordash"
                      src={logoDoordash}
                    />
                  </div>
                  <div className="c-logo-bar__logo">
                    <img 
                      alt="IHG"
                      src={logoIHG}
                    />
                  </div>
                  <div className="c-logo-bar__logo">
                    <img 
                      alt="Pinterest"
                      src={logoPinterest}
                    />
                  </div>
                  <div className="c-logo-bar__logo">
                    <img 
                      alt="Progressive"
                      src={logoProgressive}
                    />
                  </div>
                  <div className="c-logo-bar__logo">
                    <img 
                      alt="Walgreens"
                      src={logoWalgreens}
                    />
                  </div>
                </div>
              </div>
              
            </section>
            <section className="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-center l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div className="u-spacing--and-half">
                    <h3 className="o-section-title o-section-title--heavy">
                      Eliminate manual work
                    </h3>
                    <p>
                      Integrate translation with your tech stack using Smartling's automated workflows. Easily pair your content software with Smartling’s TMS through our pre-built integrations, custom APIs, or the Global Delivery Network. With automation in place, you can improve efficiency, get content to market faster, and concentrate on more significant priorities.
                    </p>
                    <a
                      role="button"
                      href="/software/"
                      className="o-link"
                    >
                      Browse our integrations
                    </a>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div className="c-product-intro-image">
                    <img loading="lazy" src={eliminateImage} alt="Eliminate Manual Work" />
                  </div>
                </div>
              </div>
            </section>

            <section className="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-center l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div className="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={improveTranslationImage}
                      alt="Improve Translation"
                    />
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div className="u-spacing--and-half">
                    <h3 className="o-section-title o-section-title--heavy">
                      Improve translation quality and consistency
                    </h3>
                    <div className="u-spacing">
                      <p>Smartling helps you evaluate, manage, and improve your language translation quality with tools and reporting built around the Multidimensional Quality Metrics framework. You can perform quality assurance directly within Smartling using our Linguistic Quality Assurance tools, and review results in a comprehensive, quality dashboard.</p>

                      <p>If you're looking for even more reliability, <a href="/translation-services/">Smartling Translation</a> comes with a quality satisfaction guarantee.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-center l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div className="u-spacing--and-half">
                    <h3 className="o-section-title o-section-title--heavy">
                      Scalable translation for the whole organization
                    </h3>
                    <div className="u-spacing">
                      <p>Smartling is designed to meet the translation needs of  your whole company—all in one place. You get unlimited users at no extra cost and in-platform communication features that make collaboration a breeze.</p>

                      <p>Plus, translation owners can empower their colleagues to customize jobs and translation projects while maintaining control and consistency across their account.</p>
                    </div>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div className="c-product-intro-image">
                    <img loading="lazy" src={scalableImage} alt="Scalable translation" />
                  </div>
                </div>
              </div>
            </section>

            <Section className="c-section--testimonial o-section u-padding--sext--top u-padding--quint--bottom">
              <Section.Inner m>
                <div className="o-testimonial--block">
                  <div className="u-space--quad--bottom">
                    <h3 className="o-testimonial--quote u-text--line-height--tight ">
                      At the end of the day, Smartling’s technology is incredibly reliable, and the customer experience is fantastic.
                    </h3>
                  </div>
                  <div className="o-testimonial--block--content">
                    <img
                      src={logoYext}
                      alt='Yext'
                      width="50"
                      height="50"
                      loading="lazy"
                    />
                    <div className="o-testimonial--block__author">
                      <p className="u-font--m u-font-weight--bold o-testimonial--block__author--tile">
                        Jessica Birenz
                      </p>
                      <p className="u-font--m o-testimonial--block__author--tile">
                        Director of Planning and Project Management, Yext
                      </p>
                    </div>
                    <Link
                      to='/resources/case-study/yext/ '
                      className="o-link u-color--primary o-testimonial--block__link"
                    >
                      Read the Case Study
                    </Link>
                  </div>
                </div>
              </Section.Inner>
            </Section>
            
            <section className="o-section--inner l-wrap l-container--l u-padding--quad--bottom">
              <div className="u-spacing--triple">
                <h3 className="o-section-title o-section-title--heavy u-text-align--center">
                  Frequently Asked Questions
                </h3>
                <div>
                  {accordionData.map((data, index) => (
                    <AccordionTable key={index} data={data} />
                  ))}
                </div>
              </div>
            </section>
            

            <section className="o-section c-section--resources-to-take-you-further" id="resources-to-take-you-further">
              <div className="c-section__header">
                <div className="c-block undefined u-spacing">
                  <div className="c-block__text u-spacing">
                    <div className="u-spacing">
                      <h2>Resources to take you further</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="o-section--inner l-wrap l-container--xl">
                <div className="l-grid l-grid--3up">
                  <div className="l-grid-item">
                    <div className="c-card u-spacing ">
                      <div className="c-card__body u-spacing--half">
                        <div className="c-card__image">
                          <img loading="lazy"
                            src={caseYext}
                            alt="How Yext translated million words to support teams around the world" />
                        </div>
                        <div className="u-spacing--half">
                          <h5 className="c-card__subtitle">Case Study</h5>
                          <h4 className="c-card__title"><a href="/resources/case-study/yext/">How Yext translated million words to support teams around the world</a></h4>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div className="l-grid-item">
                    <div className="c-card u-spacing ">
                      <div className="c-card__body u-spacing--half">
                        <div className="c-card__image">
                          <img loading="lazy"
                            src={caseIHG}
                            alt="How IHG delivered personal touch across 5,600 hotels in 16 languages" />
                        </div>
                        <div className="u-spacing--half">
                          <h5 className="c-card__subtitle">Case Study</h5>
                          <h4 className="c-card__title"><a href="/resources/case-study/intercontinental-hotels-group/">How IHG delivered personal touch across 5,600 hotels in 16 languages</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="l-grid-item">
                    <div className="c-card u-spacing">
                      <div className="c-card__body u-spacing--half">
                        <div className="c-card__image">
                          <img loading="lazy"
                            src={caseClassPass}
                            alt="How ClassPass redefined localization and expanded user research" />
                        </div>
                        <div className="u-spacing--half">
                          <h5 className="c-card__subtitle">Case Study</h5>
                          <h4 className="c-card__title"><a href="/resources/case-study/classpass-localization-and-user-research/">How ClassPass redefined localization and expanded user research</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="u-center-block u-text-align--center u-space--double--top">
                <a className="o-button" href="/resources/case-study/" role="button" target="_self">
                  Explore case studies
                </a>
              </div>
            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default ManagePage
