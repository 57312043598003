import React from 'react'
import PropTypes from '../prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'
import { getTagPath } from '../routes'

const FilterNavigationSmall = () => (
  <StaticQuery
    query={graphql`
      query FilterNavigationSmallQuery {
        categories: allContentfulResourceCategory {
          ...ContentfulResourceCategoryFragment
        }
        tags: allContentfulResourceTag {
          ...ContentfulResourceTagFragment
        }
      }
    `}
    render={(data) => (
      <React.Fragment>
        <h5 className="c-filter-nav--title">Select a category:</h5>
        <Nav>
          <Nav.SubMenu
            prefix="c-filter-nav__dropdown"
            title="Categories"
            subClasses="c-filter-nav__item--categories"
          >
            <div className="c-filter-nav__dropdown--inner u-spacing--quarter">
              <Nav.Item title="Case Study" to="/resources/case-study" />
              <Nav.Item title="Press" to="/resources/press-release" />
              <Nav.Item title="Blog" to="/resources/today" />
            </div>
          </Nav.SubMenu>
          <Nav.SubMenu
            prefix="c-filter-nav__dropdown"
            title="Tags"
            subClasses="c-filter-nav__item--tags"
          >
            <div className="c-filter-nav__dropdown--inner u-spacing--quarter">
              {data.tags &&
                data.tags.edges.map(({ node }) => (
                  <Nav.Item title={node.name} to={getTagPath(node.key)} />
                ))}
            </div>
          </Nav.SubMenu>
        </Nav>
      </React.Fragment>
    )}
  />
)

const Nav = ({ children }) => (
  <nav className="c-filter-nav--small">{children}</nav>
)
const PrefixContext = React.createContext(`c-filter-nav`)

Nav.propTypes = {
  children: PropTypes.children.isRequired,
}

Nav.Item = ({ to, title, className }) => (
  <Link exact to={to} className={className} activeClassName="this-is-active">
    {title}
  </Link>
)

Nav.Item.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

Nav.SubMenu = ({ prefix, to, title, children, subClasses }) => (
  <PrefixContext.Consumer>
    {(parentPrefix) => (
      <div className={`${parentPrefix}__item has-subnav ${subClasses || ''}`}>
        {/* eslint-disable-next-line no-script-url */}
        <a
          href={to || `#`}
          className={`${parentPrefix}__link o-arrow-indicator`}
          onClick={addActive.bind(this)}
        >
          {title}
        </a>
        <div className={`${prefix} u-spacing--quarter`}>
          <PrefixContext.Provider value={prefix}>
            {children}
          </PrefixContext.Provider>
        </div>
      </div>
    )}
  </PrefixContext.Consumer>
)

Nav.SubMenu.propTypes = {
  prefix: PropTypes.string.isRequired,
  to: PropTypes.string,
  subClasses: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.children.isRequired,
}

function addActive(e) {
  if (window.innerWidth <= 800) {
    e.preventDefault()
    e.target.parentNode.classList.toggle('this-is-active')
  }
}

export default FilterNavigationSmall
