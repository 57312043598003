import React from 'react'
import Block from 'molecules/block'
import Section from 'atoms/section'
import * as Icons from 'atoms/icons'
import Plx from 'react-plx'

// Sections

// Images
import imageNobadquestions from '../../images/question.png'
import imageApi from '../../images/illustrations/illustration_api.png'
import imageChart from '../../images/yext-chart1.gif'
import imageTms from '../../images/yext-tms2.png'
import imageStat from '../../images/yext-stat1.png'
import imageClouds from '../../images/japan-clouds.png'
import imageYextWoman from '../../images/yext-hero.png'
import imageYextGlossary from '../../images/yext-glossary.gif'
import imageYextContext from '../../images/yext-visual-context.png'
import imageYextLogo from '../../images/yext-logo.svg'

const textData1 = [
  {
    start: '.o-sticky-text__trigger',
    duration: '300vh',
    properties: [
      {
        startValue: 0,
        endValue: 90,
        unit: 'vh',
        property: 'translateY',
      },
    ],
  },
]

const textData2 = [
  {
    start: '.o-sticky-text__trigger2',
    duration: '300vh',
    properties: [
      {
        startValue: 0,
        endValue: 25,
        unit: 'vh',
        property: 'translateY',
      },
    ],
  },
]

const YextContent = () => (
  <article className="l-article c-resource-yext">
    <section class="o-section--inner l-wrap l-container--l u-background-accent--right u-background-accent--right-less-offset c-product-intro-section">
      <div className="l-flex l-flex-gap--40">
        <div className="l-flex-item l-flex-item--60 l-flex-item--swap-mobile-order-2">
          <div class="u-spacing--and-half">
            <p>Yext is on a mission to put more truth online. They do that by helping businesses around the world deliver official answers to customer questions across the entire search ecosystem—starting with a brand’s website, then extending across search engines, voice assistants, maps, and more than 175 global platforms.</p>
            <p>As Yext continues to break into new international markets, localizing marketing and product materials are increasingly critical components to scaling the business globally.</p>
            
            <h2 class="u-font--l u-text-color--purple">Challenge</h2>
            <p>Before partnering with Smartling, Yext’s translation process was highly manual and difficult to scale for the translation volume they needed. In addition, they wanted to create an entire multilingual experience for customers, which meant that they needed to translate now only their website and product, but also sales decks, marketing collateral, white papers, and more. As they launched their product in more countries, this manual process would quickly become untenable.</p>
            
            <h2 class="u-font--l u-text-color--purple">Solution</h2>
            <p>After adopting Smartling, Yext focused on establishing a robust and automated process so that they could scale. Yext connected their tech stack to Smartling’s translation management system through pre-built WordPress and code repository connectors. This approach allowed them to greatly increase in volume and expansion into new languages and enabled the entire company, both existing and new teams, to access Smartling's cloud TMS and language services with minimal setup and effort.</p>
            
            <p>The results from Yext’s launch in Japan demonstrated how much the technology and collaboration impacted their outcomes. Over the course of the project, the correction rate for Japanese strings dropped to just 0.17%, an 87% decrease in words that had to be edited. Yext was also able to benefit from a comprehensive translation memory, accumulated over time, which contributed to a 25% decrease in their effective cost per word, despite a sixfold increase in the number of supported languages.</p>
            
            <p>On top of all of the technology that automates the process surrounding human translation, it was the relationship facilitated by Smarlting’s customer engagement and translation services team that made it possible for Yext to translate the sheer volume of content into so many languages possible.</p>
          </div>
          
          <div class="u-space--top u-space--bottom">
            <p>
              <strong>Want to hear more about Yext’s journey with Smartling? Watch From Zero to Localization Hero webinar below or on <a href="https://vimeo.com/488629197">Vimeo</a>
              </strong>
            </p>
          </div>
          
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/488629197?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;margin:0;" title="How to Translate Yext Location Data | Smartling 101"></iframe></div>
        </div>
        <div className="l-flex-item l-flex-item--40 l-flex-item--swap-mobile-order-1 u-padding--top">
          <blockquote>
            <p>The Smartling platform enables us to get business done right now. If somebody were to tell me that we weren't going to continue working with Smartling, I would probably cry! At the end of the day, Smartling’s technology is incredibly reliable, and the customer experience is fantastic.</p>
            <p><strong>Jessica Birenz</strong><br/>Yext, Director of Planning and Project Management</p>
            <img
              loading="lazy"
              src={imageYextLogo}
              alt="Yext Logo"
            />
          </blockquote>
        </div>
      </div>
    </section>
    
    {/* <Section>
      <Section.Inner s className="u-text-align--center">
        <h3>
          Watch this webinar on how to move from global to
          <br />
          multi-local experiences for future success.
        </h3>
        <iframe
          src="https://resource.smartling.com/l/703963/2021-11-30/2m5pl8"
          type="text/html"
          frameborder="0"
          title="pardot form"
          allowTransparency="true"
          scrolling="no"
          style={{
            margin: '15px auto',
            border: '0',
            height: 'auto',
            minHeight: '100%',
            width: 'auto',
            minWidth: '100%',
          }}
          id="pardotForm"
          className="pardot-responsive"
        ></iframe>
      </Section.Inner>
    </Section> */}

    {/* <section class="o-section--inner l-wrap l-container--l c-product-intro-section u-space--bottom">
      <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-grid--reverse--sm">
        <div className="l-grid-item u-position--relative">
          <div class="c-product-intro-image">
            <img loading="lazy" src={imageYextWoman} alt="Jessica Birenz" />
          </div>
        </div>
        <div className="l-grid-item">
          <div class="u-spacing--and-half">
            <p>
              <a
                href="https://www.linkedin.com/in/jessica-birenz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Jessica Birenz
              </a>{' '}
              is the Director of Planning and Project Management at Yext. She’s
              one of several people at Yext who works with Smartling on a daily
              basis. Over the past six years, Yext has significantly increased
              their translation volume and launched over two dozen new languages
              all in the name of making customer experiences better for
              consumers worldwide.
            </p>
          </div>
        </div>
      </div>
    </section> */}
{/* 
    <Section centerCTA>
      <Section.Header>
        <Block title="The Challenge">
          <Block.Image>
            <Icons.IconErrorCircleLight xxxl />
          </Block.Image>
          A Highly-Manual Process That Doesn’t Scale for Translation Volume and
          Diverse Content Types For Yext
        </Block>
      </Section.Header>
    </Section> */}

    {/* <section class="o-section--inner l-wrap l-container--xl">
      <div className="l-grid l-grid--2up l-grid--align-top">
        <div className="l-grid-item">
          <h3 class="o-section-title o-section-title--heavy">
            Yext begins with the translation basics
          </h3>
          <div class="c-timeline c-timeline--left">
            <div class="c-timeline__item u-spacing--and-half">
              <p>
                In 2014, Yext began using Smartling to translate its help site
                and product with an API integration. That year, Yext translated
                211,947 words, a humble beginning that demonstrated the value
                and importance of content translation to Yext.
              </p>
              <p>
                But, this was just the beginning. Yext quickly expanded the
                scope of content that would be translated because it was
                important to have a rich user experience.
              </p>
              <p class="c-quote--alt">
                “Language translation for Yext extends beyond the website and
                product. It's an integral requirement for a well-rounded
                customer experience, which is why we also translate our sales
                decks, marketing collateral, white papers, webinar decks, and
                all of the content that is part of a modern marketing stack.”
              </p>
            </div>

            <div class="c-timeline__item u-spacing--and-half">
              <p>
                Because accurate and comprehensive coverage of in-language
                content helps Yext support their teams and customers, Yext
                expanded the scope of translated content to go beyond their
                website and web application. It’s business-as-usual for Yext to
                translate content as detailed as the speaker talk-track in
                presentation decks, client success documents, Whitepapers,
                PDF’s, webinars, and all of the crucial marketing and sales
                materials necessary to support its international teams. If
                that’s not enough, Yext also translates its customer’s content
                in Smartling.
              </p>
              <p>
                The result? Yext has exceeded{' '}
                <b>
                  <em>12 million words</em>
                </b>{' '}
                translated since they began working with Smartling in 2014.
              </p>
              <p class="c-quote--alt">
                “I've always been really happy with how quick we can get things
                done and how easy it is to flag priority content. I don't think
                I've ever really been told this is impossible, despite how much
                content we translate.”
              </p>
            </div>
          </div>
        </div>

        <div className="l-grid-item u-padding--top">
          <div class="u-position--relative">
            <div class="o-sticky-text__trigger"></div>
            <div class="o-sticky-text__wrapper">
              <Plx
                className="o-sticky-text u-text-align--center"
                parallaxData={textData1}
              >
                <div>
                  <p>
                    <img
                      loading="lazy"
                      src={imageApi}
                      alt="Yext API"
                      class="u-max-width--70"
                    />
                  </p>
                </div>
              </Plx>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    {/* <section class="o-section " data-bgset="">
      <div class="c-section__header">
        <div class="c-block undefined u-spacing">
          <div class="c-block__text u-spacing">
            <div class="u-spacing">
              <h3 class="c-block__title">
                Yext’s Translation Volume <nobr>Year-Over-Year</nobr>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="o-section--inner l-wrap l-container--l">
        <img
          loading="lazy"
          src={imageChart}
          alt="graph of Yext's Translation Volume Year-Over-Year -- under 1 million to 3 million from 2014 to 2020"
        />
      </div>
    </section> */}

    {/* <section class="o-section--inner l-wrap l-container--xl">
      <div className="l-grid l-grid--2up l-grid--align-top l-grid--reverse--sm">
        <div className="l-grid-item u-padding--top">
          <div class="u-position--relative">
            <div class="o-sticky-text__trigger2"></div>
            <div class="o-sticky-text__wrapper">
              <Plx
                className="o-sticky-text u-text-align--center"
                parallaxData={textData2}
              >
                <div>
                  <p>
                    <img
                      loading="lazy"
                      src={imageClouds}
                      alt="Japanese flag on top of colorful clouds"
                      class="u-max-width--70"
                    />
                  </p>
                </div>
              </Plx>
            </div>
          </div>
        </div>
        <div className="l-grid-item">
          <h3 class="o-section-title o-section-title--heavy">
            The Challenges Inherent of Adding Support for Japanese
          </h3>
          <div class="c-timeline c-timeline--right">
            <div class="c-timeline__item u-spacing--and-half">
              <p>
                In 2018, Yext has a go-to-market initiative in Japan. This meant
                the entire website, marketing materials, the web-app, and more
                had to be translated into Japanese. All with just one marketer
                based in Japan at the time.
              </p>
              <p class="c-quote--alt">
                “We launched the Japanese site in 2018 with limited resources in
                Japan: only one marketer. She’s incredible, but she's just one
                person!”
              </p>
            </div>

            <div class="c-timeline__item u-spacing--and-half">
              <p>
                The Japanese language and culture are incredibly nuanced, and
                for a company that uses a lot of American English slang as a
                part of their brand identity, it was challenging to create
                translations that made sense for Japanese consumers with the
                same tone that Yext customers love.
              </p>
            </div>

            <div class="c-timeline__item u-spacing--and-half">
              <p>
                But Yext wasn’t going to give up on their Japanese launch just
                yet…
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    {/* <Section centerCTA>
      <Section.Header>
        <Block title="The Solution">
          <Block.Image>
            <Icons.IconLightbulbCircleLight xxxl />
          </Block.Image>
          Yext Works Closely With Smartling to Improve Workflow, Increase
          Translation Accuracy, and Scale for Volume
        </Block>
      </Section.Header>
    </Section> */}

    {/* <section class="o-section--inner l-wrap l-container--l u-background-accent--right u-background-accent--right-less-offset u-background-accent--right-top-offset c-product-intro-section">
      <div className="l-grid l-grid--2up l-grid--100-column-gap l-grid--align-top">
        <div className="l-grid-item">
          <div class="u-spacing--and-half">
            <h3 class="o-section-title o-section-title--heavy">
              Yext and Smartling Work Together
            </h3>
            <p>
              When Yext first started using Smartling, it was important to
              create a strong, largely automated process to establish a
              foundation for Yext to scale their language translation
              requirements. With Smartling’s translation management system as
              the primary technology that centralized Yext’s translation to one
              platform, the company also leveraged the WordPress integration for
              their website and code repository connector for their product.
              This enabled Jessica and other teams at Yext to increase their
              volume and scale into new languages as the company’s global plans
              came to fruition.
            </p>
            <p class="c-quote--alt">
              “The amount of effort that we put into the initial setup - the
              workflows, translation memory configuration, and the back-end
              automation - is incredibly useful.”
            </p>
            <p>
              The entire company has access to Smartling’s cloud TMS and can use
              Smartling language services with minimal setup and effort to get
              started. This includes existing and new teams.
            </p>
          </div>
        </div>
        <div className="l-grid-item u-padding--top">
          <div class="c-product-intro-image u-position--relative u-align--center">
            <img loading="lazy" src={imageTms} alt="Yext TMS" />
          </div>
        </div>
      </div>
    </section> */}

    {/* <section class="c-product-intro-section">
      <div class="u-spacing--and-half">
        <div class="l-wrap l-container--s">
          <h3 class="o-section-title o-section-title--heavy u-text-align--center">
            Lessons learned from launching in Japan
          </h3>
        </div>
        <div class="u-spacing--and-half l-wrap l-container--m">
          <p>
            When Smartling submitted the first round of translations for the
            Yext launch in Japan, the Yext team recognized that while the
            translations were accurate, they fell short on reflecting the Yext
            product and brand nuances - a common challenge when launching a new
            language. So, Smartling and Yext got to work.
          </p>
          <img
            loading="lazy"
            src={imageYextGlossary}
            alt="Yext Glossary"
            class="u-padding--top u-padding--bottom"
          />
          <p>
            Jessica reached out directly to Smartling’s Language Services team,
            who showed Jessica a few different approaches to help fix the issues
            including: updating the style guide, which had been edited only a
            few times since its original creation, and updating the glossary.
            They were updated to use brand terminology and product names with
            explicit instructions as to how the content should be translated.
            Smartling also scheduled a phone call between Yext’s Japanese team
            and Smartling’s Japanese translator.
          </p>
          <p class="c-quote--alt u-padding u-font--l">
            “We ended up getting on a call with Smartling’s Japanese translator
            and translation service team to talk through our preferences for
            Japanese content. This isn’t normal for the industry, having the
            ability to speak directly with the translator; I was so impressed.”
          </p>
          <p>
            Because of Jessica and the Yext team’s foundational work with
            Smartling, we worked together to create Japanese translations that
            fit their brand with the Japanese language and culture.
          </p>
          <div class="u-spacing--half">
            <img
              loading="lazy"
              src={imageYextContext}
              alt="Yext Visual Context"
              class="u-padding--top"
            />
            <span class="u-font--xs u-display--block u-text-style--italic u-padding--left u-padding--right">
              Smartling's Visual Context editor allows translators to get the
              translations right the first time, eliminating errors and
              ambiguity with your content.
            </span>
          </div>
        </div>
      </div>
    </section> */}

    {/* <Section centerCTA>
      <Section.Header>
        <Block title="The Results">
          <Block.Image>
            <Icons.IconStarCircleLight xxxl />
          </Block.Image>
          Yext Achieves Correction Rate of 0.17% for Japanese translations
        </Block>
      </Section.Header>
    </Section> */}

    {/* <section class="o-section--inner l-wrap l-container--l u-background-accent--right u-background-accent--right-less-offset u-background-accent--right-top-offset c-product-intro-section">
      <div className="l-grid l-grid--2up l-grid--100-column-gap l-grid--align-top">
        <div className="l-grid-item">
          <div class="u-spacing--and-half">
            <p>
              With an updated style guide, glossary, and direct communication
              with their Smartling translator, Yext was able to reduce their
              correction rate of Japanese strings to just 0.17%, and an 87%
              decrease in the number of words edited from 2018 to 2020. The
              result of this collaboration is that Yext can now launch their
              product in Japanese and other markets with greater confidence and
              with less work.
            </p>
            <p>
              Over time, Yext has also amassed a comprehensive translation
              memory, which reduced their effective cost per word by 25% even
              after having increased the number of languages supported by six
              times.
            </p>
            <p>
              Smartling has become such a strategic resource for Yext that
              Jessica couldn’t imagine what life would be life without
              Smartling.
            </p>
            <p>
              Smartling has helped Yext launch in locations around the world,
              and grow into an established international company. On top of all
              the technology that automates the process surrounding human
              translation, it was the relationship facilitated by Smarlting’s
              customer engagement and translation services team that made it
              possible for Yext to translate the sheer volume of content into so
              many languages possible.
            </p>
          </div>
        </div>
        <div className="l-grid-item u-padding--top">
          <div class="c-product-intro-image u-position--relative u-align--center ">
            <img
              loading="lazy"
              src={imageStat}
              alt="25% reduction in cost per word"
            />
          </div>
        </div>
      </div>
    </section> */}

    {/* <div className="u-gradient--secondary u-padding--section">
      <div class="c-section-container c-section-container--white">
        <section class="o-section--inner l-wrap l-container--xl">
          <div class="u-spacing--sections">
            <div class="c-quote-box">
              <div class="u-spacing--double">
                <div class="o-section-title u-spacing--double o-section-title--small c-quote o-section-title--medium">
                  “The Smartling platform enables us to get business done right
                  now. If somebody were to tell me that we weren't going to
                  continue working with Smartling, I would probably cry!{' '}
                  <span role="img" aria-label="laughing crying emoji">
                    😂
                  </span>{' '}
                  <br />
                  <br />
                  At the end of the day, Smartling’s technology is incredibly
                  reliable, and the customer experience is fantastic.”
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div> */}

    {/* <section className="o-section c-section__cta c-cta c-cta--center">
      <div className="o-section--inner l-wrap l-container--l">
        <div className="c-cta__content u-spacing u-text-align--center">
          <h2 className="c-cta__title">Like what you see?</h2>
          <div className="c-cta__description">
            Get in touch with us to see how Smartling can help support your
            brand’s global vision.
          </div>
        </div>
        <div className="c-cta__buttons">
          <a className="o-button  " href="/meeting-request/" role="button">
            Schedule a Meeting
          </a>
        </div>
      </div>
    </section> */}
  </article>
)

export default YextContent
