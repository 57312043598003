import React, { useState, useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { getResourcePath } from '../routes'
import Slider from 'react-slick'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
import Image from 'atoms/contentful/image'
import BlockResources from 'molecules/block-resources'
import BlockFeatured from 'molecules/block-featured-resource'
import BlockLearningHubs from '../01-molecules/block-learning-hub'
import Pagination from 'molecules/pagination'
import SectionCta from 'organisms/cta'
import { getCategoryPath } from '../routes'

// Images
import locCoach from '../images/loc-coach-img.png'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import FilterNavigationSmall from 'organisms/filter-navigation-small'
import Layout from 'layouts'

// Set the number of posts to be visible per page
let PageSize = 6

const ResourcesPage = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [activeCategory, setActiveCategory] = useState('all')
  let [filteredCount, setFilteredCount] = useState(data.all.totalCount)

  const handleClick = (e) => setActiveCategory(e)

  let getQuery = (category) => {
    switch (category) {
      case 'all':
        setFilteredCount(data.all.totalCount)
        return data.all.edges
      case 'blog':
        setFilteredCount(data.aboutLocalization.totalCount)
        return data.aboutLocalization.edges
      case 'caseStudy':
        setFilteredCount(data.customerSuccess.totalCount)
        return data.customerSuccess.edges
      case 'eBooks':
        setFilteredCount(data.ebook.totalCount)
        return data.ebook.edges
      case 'webinars':
        setFilteredCount(data.webinars.totalCount)
        return data.webinars.edges
      case 'product':
        setFilteredCount(data.product.totalCount)
        return data.product.edges
      case 'culture':
        setFilteredCount(data.culture.totalCount)
        return data.culture.edges
      default:
        setFilteredCount(data.all.totalCount)
        return data.all.edges
    }
  }

  const resourcesData = getQuery(activeCategory)
  const firstResource = data.first.edges[0].node
  const currentPageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return resourcesData.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, resourcesData])

  return (
    <Layout>
      {data.learningCenter &&
        data.learningCenter.edges.map(({ node }) => (
          <React.Fragment>
            <Metadata
              title={node.metadataTitle || `${node.name} - Smartling`}
              description={node.metadataDescription}
              keywords={
                node.metadataKeywords && node.metadataKeywords.join(`, `)
              }
              image={node.metadataImage && node.metadataImage.file.url}
            />
            <div className="c-page--resources">
              <Section
                gradientTertiary
                className="c-hero__header c-hero__header--resources u-padding--quad--top u-space--quad--bottom u-padding--section u-box-shadow"
              >
                <Section.Header>
                  <Block title={node.name}>
                    <Block.Image />
                    {node.description}
                  </Block>
                </Section.Header>
                <Section.Inner xl>
                  {data.featured &&
                    data.featured.edges.map(
                      ({ node }) =>
                        node.featuredResource && (
                          <Hero
                            flip
                            title={node.featuredResource.title}
                            className={
                              node.featuredResource.type
                                ? `${`c-hero__resources o-kicker__${node.featuredResource.type
                                    .toLowerCase()
                                    .replace(' ', '-')}`}`
                                : `c-hero__resources`
                            }
                          >
                            <Hero.Kicker>
                              <Link
                                to={getCategoryPath(
                                  node.featuredResource.categories.key
                                )}
                              >
                                {node.featuredResource.categories.name}
                              </Link>
                            </Hero.Kicker>
                            <Hero.Description>
                              {node.featuredResource.description}
                            </Hero.Description>
                            {node.featuredResource.image && (
                              <Hero.Image>
                                <Link to="/resources/ebook-download/translation-efficiency-guide">
                                  <Image {...node.featuredResource.image} />
                                </Link>
                              </Hero.Image>
                            )}
                            <Hero.Buttons>
                              <Button url="/resources/ebook-download/translation-efficiency-guide">
                                Download Now
                              </Button>
                            </Hero.Buttons>
                          </Hero>
                        )
                    )}
                </Section.Inner>
              </Section>
            </div>
          </React.Fragment>
        ))}

      <div className="l-container">
        <Section>
          <Section.Inner xl className="u-space--double--bottom">
            <Grid className="u-align--top" twoUp>
              <Grid.Item>
                <div style="display: flex; justify-content: space-between;">
                  <a
                    role="button"
                    id="blog"
                    className={
                      activeCategory === 'blog'
                        ? 'o-button'
                        : 'o-button o-button--outline'
                    }
                    onClick={(e) => {
                      handleClick(e.target.id)
                    }}
                    // href="/resources/today"
                  >
                    Blog
                  </a>
                  <a
                    role="button"
                    id="caseStudy"
                    className={
                      activeCategory === 'caseStudy'
                        ? 'o-button'
                        : 'o-button o-button--outline'
                    }
                    onClick={(e) => {
                      handleClick(e.target.id)
                    }}
                  >
                    Case Study
                  </a>
                  <a
                    role="button"
                    id="eBooks"
                    className={
                      activeCategory === 'eBooks'
                        ? 'o-button'
                        : 'o-button o-button--outline'
                    }
                    onClick={(e) => {
                      handleClick(e.target.id)
                    }}
                    // href="/resources/ebook"
                  >
                    eBooks
                  </a>
                </div>
              </Grid.Item>
              <Grid.Item>
                <div style="display: flex; justify-content: space-between;">
                  <a
                    role="button"
                    id="webinars"
                    className={
                      activeCategory === 'webinars'
                        ? 'o-button'
                        : 'o-button o-button--outline'
                    }
                    onClick={(e) => {
                      handleClick(e.target.id)
                    }}
                    // href="/resources/webinar"
                  >
                    Webinars
                  </a>
                  <a
                    role="button"
                    id="product"
                    className={
                      activeCategory === 'product'
                        ? 'o-button'
                        : 'o-button o-button--outline'
                    }
                    onClick={(e) => {
                      handleClick(e.target.id)
                    }}
                    // href="/resources/product-brief"
                  >
                    Product
                  </a>
                  <a
                    role="button"
                    id="culture"
                    className={
                      activeCategory === 'culture'
                        ? 'o-button'
                        : 'o-button o-button--outline'
                    }
                    onClick={(e) => {
                      handleClick(e.target.id)
                    }}
                    // href="/resources/lifestyle"
                  >
                    Culture
                  </a>
                </div>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <Section>
          <Section.Inner xl>
            <BlockResources
              resourcesData={currentPageData}
              firstResource={firstResource}
              showFirst={activeCategory === 'all'}
            />
          </Section.Inner>
        </Section>

        <Pagination
          className="c-pagination-container u-space--bottom"
          currentPage={currentPage}
          totalCount={filteredCount}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
          activeCategory={activeCategory}
        />
      </div>

      <article className="l-article u-space--zero--bottom">
        <section className="c-product-intro-section u-gradient--senery-alt u-padding--section ">
          <div className="u-spacing--and-half l-container l-wrap">
            <Grid twoUp>
              <Grid.Item>
                <div className="u-spacing--double">
                  <h3 className="c-block__title o-section-title o-section-title--heavy">
                    The Loc Coach
                  </h3>
                  <p className="c-cta__description">
                    A comprehensive resource hub brought to you by Smartling and
                    Localization Career Coach, Hristina Racheva
                  </p>
                  <a
                    className="o-button o-button--white u-color--primary"
                    href="/resources/learning-hub/localization-coach/"
                  >
                    Learn More
                  </a>
                </div>
              </Grid.Item>
              <Grid.Item>
                <div className="u-spacing--double u-max-width--66--dt">
                  <img
                    loading="lazy"
                    src={locCoach}
                    style={{
                      maxHeight: '450px',
                      position: 'absolute',
                      bottom: 0,
                    }}
                  />
                </div>
              </Grid.Item>
            </Grid>
          </div>
        </section>
      </article>
      <SectionCta
        title="Let's Talk Localization"
        centerCTA
        className="u-padding--triple"
      >
        <SectionCta.Description>
          Learn how Smartling can help you bring your brand to customers around
          the globe through translation.
        </SectionCta.Description>
        <SectionCta.Buttons>
          <a className="o-button o-button--primary" href="/meeting-request/">
            Learn More
          </a>
        </SectionCta.Buttons>
      </SectionCta>
    </Layout>
  )
}

export default ResourcesPage

export const query = graphql`
  query ResourcesPageQueryNew {
    learningCenter: allContentfulResourceCategory(
      filter: { key: { eq: "resources" } }
    ) {
      ...ContentfulResourceCategoryFragment
    }

    featured: allContentfulResourceCategory(
      limit: 1
      filter: { key: { eq: "resources" } }
    ) {
      ...ContentfulResourceCategoryFragment
    }

    latest: allContentfulResource(
      limit: 66
      sort: { fields: [publishDate], order: DESC }
    ) {
      ...ContentfulLatestResourceFragment
    }

    all: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      skip: 1
      filter: {
        sys: { revision: { ne: null } }
        categories: {
          key: {
            in: [
              "today"
              "product"
              "101"
              "case-study"
              "resources"
              "global-ready-conference"
              "ebook-download"
            ]
          }
        }
      }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    first: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      limit: 1
      filter: {
        sys: { revision: { ne: null } }
        categories: {
          key: {
            in: [
              "today"
              "product"
              "101"
              "case-study"
              "resources"
              "global-ready-conference"
            ]
          }
        }
      }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    customerSuccess: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { key: { eq: "case-study" } } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    aboutLocalization: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { key: { eq: "101" } } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    product: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { key: { eq: "product" } } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    globalReady: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { key: { eq: "global-ready-conference" } } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    resources: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { key: { eq: "resources" } } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    ebook: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { type: { eq: "Ebook" } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    webinars: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { tags: { elemMatch: { key: { in: "webinar" } } } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    lifestyle: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { tags: { elemMatch: { key: { in: "lifestyle" } } } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    todayAtSmartling: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { key: { eq: "today" } } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    culture: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: {
        tags: { elemMatch: { key: { in: "lifestyle" } } }
        categories: { key: { in: "today" } }
      }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    productBrief: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { tags: { elemMatch: { key: { in: "product-brief" } } } }
    ) {
      ...ContentfulSimpleResourceFragment
    }

    learningHubPages: allContentfulLearningHubPage(
      limit: 55
      sort: { fields: [publishDate], order: DESC }
    ) {
      ...ContentfulLearningHubPageFragment
    }
  }
`
