import React from 'react'
import PropTypes from '../prop-types'
import Helmet from 'react-helmet'
import favicon from '../../src/images/favicon.png'
const metaImage = "https://images.ctfassets.net/a8pkciehvm1g/7BF3nz4CAIFOUiiVm1t45M/4f9f094dd73aaf76750312e7313a308f/4Q221114_-_Smartling_-_1200x675_2x__1_.jpg?h=250"

const Metadata = ({
  data,
  name,
  title,
  description,
  keywords,
  image,
  url,
  noindex,
}) => (
  <Helmet>
    {name && <meta name="application-name" content={name} />}
    {title && <title>{title}</title>}
    {title && <meta name="apple-mobile-web-app-title" content={title} />}
    {description && <meta name="description" content={description} />}
    {keywords && <meta name="keywords" content={keywords} />}
    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    <meta property="og:image" content={metaImage} />
    {url && <meta property="og:url" content={url} />}


    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="google-site-verification"
      content="w1t5-GwV9yntry_HdHsE3nhx3f2bvLLY4WCaTS78FEs"
    />
    <meta name="twitter:site" content="@smartling" />
    {title && <meta name="twitter:title" content={title} />}
    {description && <meta name="twitter:description" content={description} />}
    <meta
      name="twitter:image"
      content={metaImage}
    />
    {description && <meta name="twitter:image:alt" content={description} />}

    <link href={favicon} rel="shortcut icon" />
    {noindex && <meta name="robots" content="noindex" />}
  </Helmet>
)

Metadata.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
}

export default Metadata
