import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Block from 'molecules/block'
import Button from 'atoms/button'
import Layout from 'layouts'

import Grid from 'atoms/grid'
import Helmet from 'react-helmet'
import AccordionSection from 'components/sections/accordion-section'
import Accordion from 'organisms/accordion'
import SlickSlider from 'organisms/slick-slider'
import Tabs from 'molecules/tabs'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

// Sections
import Section from 'atoms/section'
import Hero from 'organisms/hero'

// Images
import logoPeloton from '../images/homepage/Frame-56.jpg'
import logoPinterestCarousel from '../images/homepage/Frame-57.jpg'
import logoLyft from '../images/homepage/Frame-58.jpg'
import logoVimeo from '../images/homepage/Frame-59.jpg'
import logoShopify from '../images/homepage/Frame-60.jpg'
import logoCanva from '../images/homepage/Frame-61.jpg'
import logoMars from '../images/homepage/Frame-62.jpg'
import logoWilson from '../images/homepage/Frame-63.jpg'
import logoIHG from '../images/homepage/Frame-64.jpg'
import logoEurial from '../images/homepage/Frame-65.jpg'
import logoSurveyMonkey from '../images/homepage/Frame-66.jpg'
import logoWeWork from '../images/homepage/Frame-67.jpg'
import logoAdRoll from '../images/homepage/Frame-68.jpg'
import logoAdWix from '../images/homepage/Frame-69.jpg'
import logoStateFarm from '../images/homepage/Frame-70.jpg'
import logoCardPinterest from '../images/pinterest-logo.svg'

import tabOneImage from '../images/homepage/product-illustration_CAT.gif'
import tabTwoImage from '../images/homepage/tabTwoImage.jpg'
import tabThreeImage from '../images/homepage/tabThreeImage.jpg'
import tabFourImage from '../images/homepage/tabFourImage.jpg'

const heroImage = '../images/homepage/2024herohome.png'
const imageDifference = '../images/illustrations/smartling-difference.png'

const imageSilvia = '../images/silviaovideolopez.png'
const imageGavin = '../images/gavinflood.png'
const imageJessica = '../images/jessicabirenz.png'

const logoPinterest = '../images/pinterest-logo-small.svg'
const logoAdrollSmall = '../images/adroll-logo-small.svg'
const logoYext = '../images/yext-logo-small.svg'

const iconStar = '../images/icons/icon-star-bottomleft.svg'
const iconSpeed = '../images/icons/icon-speed-bottomleft.svg'
const iconDollar = '../images/icons/icon-dollarsign-topleft.svg'

const imageG2a = '../images/g2/summer-2022-1.png'
const imageG2b = '../images/g2/summer-2022-2.png'
const imageG2c = '../images/g2/summer-2022-3.png'
const imageG2d = '../images/g2/summer-2022-4.png'
const imageStars = '../images/g2-red-stars.svg'
const imageStars8of10 = '../images/stars-8of10.png'

const logoCardIHG = '../images/homepage/logo-ihg.png'
const logoCardYext = '../images/homepage/logo-yext.png'

// G2 Logos
const g2bestSoftware = '../images/g2/g2_best_software_2024_badge_contentmanagement_products.png'
const g2leaderEnterpriseEmea = '../images/g2/g2leader-enterprise-emea.svg'
const g2leaderEnterprise = '../images/g2/g2leader-enterprise.svg'
const g2LeaderMidmkt = '../images/g2/g2leader-midmkt.svg'

const logoIllustrator = '../images/company-logos/logo-illustrator-small.png'
const logoPhotoshop = '../images/company-logos/logo-photoshop-small.png'
const logoFigma = '../images/company-logos/logo-figma-small.png'
const logoContentful = '../images/company-logos/logo-contentful-small.png'
const logoSfSvcCloud = '../images/company-logos/logo-sf-svccloud-small.png'
const logoZenDesk = '../images/company-logos/logo-zendesk-small.png'
const logoContentStack = '../images/company-logos/logo-contentstack-small.png'
const logoMarketo = '../images/company-logos/logo-marketo-small.png'
const logoDrupal = '../images/company-logos/logo-drupal-small.png'
const logoSiteCore = '../images/company-logos/logo-sitecore-small.png'
const logoSanity = '../images/company-logos/logo-sanity-small.png'
const logoWordpress = '../images/company-logos/logo-wordpress-small.png'
const logoHubSpot = '../images/company-logos/logo-hubspot-small.png'
const logoOracleEloqua = '../images/company-logos/logo-oracle-eloqua-small.png'
const logoSfCommCloud = '../images/company-logos/logo-sf-commcloud-small.png'
const logoSapHybris = '../images/company-logos/logo-sap-hybris-small.png'
const logoSketch = '../images/company-logos/logo-sketch-small.png'
const logoGoogleDrive = '../images/homepage/google_drive_logo.png'

/*
 * This is the homepage of the site - behind the scenes, Gatsby will render this
 * component inside the layouts/index.js layout to create the static HTML content
 * for the homepage. By convention, Gatsby uses the default export from this file
 * as the component to render, and also accepts a `query` export to load data.
 */

const SlickSliderImages = [
  {
    src: logoPeloton,
    alt: 'Peloton Logo',
  },
  {
    src: logoPinterestCarousel,
    alt: 'Pinterest Logo',
  },
  {
    src: logoLyft,
    alt: 'Lyft Logo',
  },
  {
    src: logoVimeo,
    alt: 'Vimeo Logo',
  },
  {
    src: logoShopify,
    alt: 'Shopify Logo',
  },
  {
    src: logoCanva,
    alt: 'Canva Logo',
  },
  {
    src: logoMars,
    alt: 'Mars Logo',
  },
  {
    src: logoWilson,
    alt: 'Wilson Logo',
  },
  {
    src: logoIHG,
    alt: 'IHG Logo',
  },
  {
    src: logoEurial,
    alt: 'Eurail Logo',
  },
  {
    src: logoSurveyMonkey,
    alt: 'SurveyMonkey Logo',
  },
  {
    src: logoWeWork,
    alt: 'figma Logo',
  },
  {
    src: logoAdRoll,
    alt: 'Photoshop Logo',
  },
  {
    src: logoAdWix,
    alt: 'figma Logo',
  },
  {
    src: logoStateFarm,
    alt: 'figma Logo',
  },
]

const tabsData = [
  {
    tabTitle: 'Translate',
    tabContent: {
      image: tabOneImage,
    },
  },
  {
    tabTitle: 'Automate',
    tabContent: {
      image: tabTwoImage,
    },
  },
  {
    tabTitle: 'Manage',
    tabContent: {
      image: tabThreeImage,
    },
  },
  {
    tabTitle: 'Analytics',
    tabContent: {
      image: tabFourImage,
    },
  },
]

const mosaicData = []
class IndexPage extends React.Component {
  componentDidMount() {
    import(/* webpackChunkName: "sal" */ 'sal.js').then(({ default: sal }) => {
      //sal
      sal({
        rootMargin: '0% 0% 15%',
      })
    })
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <body className="u-header--white" />
        </Helmet>
        <div className="l-home">
          <Metadata
            title="Language Translation and Content Localization Solutions - Smartling"
            description="Smartling connects your brand with customers around the globe. Translate, automate and manage your content across platforms with real-time analytics and reporting."
            keywords=""
          />
          <Hero
            curveCenterAlt
            gradientPrimary
            title="#1 AI-Enabled Translation Platform"
            titleLink="/demo"
          >
            <Hero.Description>
              Hundreds of companies rely on Smartling's LanguageAI platform to accurately translate and localize content into any language and any media at scale.
            </Hero.Description>
            <Hero.Image>
              <StaticImage
                src={heroImage}
                alt="Start Translating"
                width={828}
                height={536}
                loading="eager"
                placeholder="blurred"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request">Book a Meeting</Button>
              <Button url="/demo">5-Min Demo</Button>
              <div className="c-hero__badges">
                <a 
                  title="G2 Best Software 2024 Badge"
                  href="https://www.g2.com/products/smartling/reviews"
                >
                  <StaticImage
                    src={g2bestSoftware} 
                    alt="G2 Best Software 2024 Badge"
                    loading="eager"
                    placeholder="blurred"
                  />
                </a>
                <a 
                  title="G2 Leader Enterprise 2024 Badge"
                  href="https://www.g2.com/products/smartling/reviews"
                >
                  <StaticImage
                    src={g2leaderEnterprise} 
                    alt="G2 Leader Enterprise 2024 Badge"
                    loading="eager"
                    placeholder="blurred"
                  />
                </a>
                <a 
                  title="G2 Leader Midmarket 2024 Badge"
                  href="https://www.g2.com/products/smartling/reviews"
                >
                  <StaticImage
                    src={g2LeaderMidmkt} 
                    alt="G2 Leader Midmarket 2024 Badge"
                    loading="eager"
                    placeholder="blurred"
                  />
                </a>
                <a 
                  title="G2 Leader Enterprise EMEA 2024 Badge"
                  href="https://www.g2.com/products/smartling/reviews"
                >
                  <StaticImage
                    src={g2leaderEnterpriseEmea}
                    alt="G2 Leader Enterprise EMEA 2024 Badge"
                    loading="eager"
                    placeholder="blurred"
                  />
                </a>
              </div>
              
            </Hero.Buttons>
            
          </Hero>

          <article className="l-article u-spacing--sections c-home-sections">
            <div class="u-gradient-grey u-spacing--sections">
              <Section className="o-section--inner l-wrap o-section--inner-home u-spacing--quad u-space--quad--top l-container--xl">
                <Grid threeUp className="l-grid--small-gap">
                  <Grid.Item>
                    <div className="u-textbox u-textbox--stats">
                      <div className="u-textbox__logo">
                        <StaticImage
                          src={logoCardIHG}
                          alt="IHG Logo"
                          width={194}
                          height={32}
                          loading="lazy"
                          placeholder="blurred"
                        />
                      </div>
                      <div className="u-spacing--half">
                        <h3 className="u-align--left">33%</h3>
                        <p className="u-font--m u-font-weight--light u-text--loose">increase in translation quality</p>
                      </div>
                      <div className="u-space--double--top">
                        <a
                          role="button"
                          href="/resources/case-study/ihg-delivering-the-personal-touch-across-5-600-hotels-in-16-languages/"
                          class="o-link u-color--primary"
                        >
                          Read the customer story
                        </a>
                      </div>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    <div className="u-textbox u-textbox--stats">
                      <div className="u-textbox__logo">
                        <StaticImage
                          src={logoCardYext}
                          alt="Yext Logo"
                          width={80}
                          height={80}
                          loading="lazy"
                          placeholder="blurred"
                        />
                      </div>
                      <div className="u-spacing--half">
                        <h3 className="u-align--left">25%</h3>
                        <p className="u-font--m u-font-weight--light u-text--loose">reduction in cost per word</p>
                      </div>
                      <div className="u-space--double--top">
                        <a
                          role="button"
                          href="/resources/case-study/yext/"
                          class="o-link u-color--primary"
                        >
                          Read the customer story
                        </a>
                      </div>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    <div className="u-textbox u-textbox--stats">
                      <div className="u-textbox__logo">
                        <img width="56px" height="56px" src={logoCardPinterest} alt="Pinterest Logo" />
                      </div>
                      <div className="u-spacing--half">
                        <h3 className="u-align--left">83%</h3>
                        <p className="u-font--m u-font-weight--light u-text--loose">faster time to market</p>
                      </div>
                      <div className="u-space--double--top">
                        <a
                          role="button"
                          href="/resources/case-study/pinterest/"
                          class="o-link u-color--primary"
                        >
                          Read the customer story
                        </a>
                      </div>
                    </div>
                  </Grid.Item>
                </Grid>
              </Section>

              <Section
                className="u-gradient--accent o-section-home--split-tabs"
                centerCTA
              >
                <Section.Inner
                  xl
                  className="o-section--inner-home u-spacing--quad u-space--quint--top"
                >
                  <div className="l-grid l-grid--2up l-grid--align-top">
                    <div className="l-grid-item">
                      <div class="u-spacing">
                        <h2 className="o-block--title">
                          Create Meaningful Experiences in Any Language
                        </h2>
                        <p className="u-space--double--top u-font--m u-font-weight--light u-text--loose">
                          Simplify the complexities of managing multilingual
                          content and get to market faster.
                        </p>
                        <div className="o-gt-list">
                          <ul>
                            <li>
                              <p className="u-font--s-m u-font-weight--light">
                                {' '}
                                Translate quickly and accurately with visual
                                context and quality checks
                              </p>
                            </li>
                            <li>
                              <p className="u-font--s-m u-font-weight--light">
                                {' '}
                                Automate content ingestion with flexible
                                workflows
                              </p>
                            </li>
                            <li>
                              <p className="u-font--s-m u-font-weight--light">
                                Manage workflow routing, visibility,
                                communication and cost
                              </p>
                            </li>
                            <li>
                              <p className="u-font--s-m u-font-weight--light">
                                Analyze and optimize performance with real time,
                                custom reporting
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="l-grid-item u-padding--top o-tabs--homepage">
                      <Tabs data={tabsData} />
                    </div>
                  </div>
                </Section.Inner>
              </Section>

              <Section centerCTA className="o-section--trans-services">
                <Section.Inner
                  xl
                  className="o-section--inner-home u-spacing--quad"
                >
                  <Grid twoUp>
                    <Grid.Item>
                      <div className="u-textbox u-spacing">
                        <h3 className="u-align--left">
                          AI-Powered Translation Management Software
                        </h3>
                        <p>
                          Smartling's translation management platform is cloud
                          based with AI-powered content and workflow management,
                          performance and progress dashboards, cost estimating,
                          and a contextual CAT Tool. Automate content ingestion
                          with our suite of pre-built integrations or our Proxy.
                        </p>
                        <div>
                          <a
                            role="button"
                            href="/software/translation-management-system/"
                            class="o-link u-color--primary"
                          >
                            Explore software
                          </a>
                        </div>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div className="u-textbox u-spacing">
                        <h3 className="u-align--left">Translation Services</h3>
                        <p className="u-space--zero">
                          Get human translation from our expert translators or
                          Neural Machine Translation customized to your brand.
                          Translators have full access to your style guide and
                          glossary to ensure quality translation. Communicate
                          directly with translators in the platform to reduce
                          translation time to market.
                        </p>
                        <div>
                          <a
                            role="button"
                            href="/translation-services/"
                            class="o-link u-color--primary"
                          >
                            Explore translation services
                          </a>
                        </div>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <p className="o-section--trans-services__kicker u-align--center u-font--s-m u-text--loose ">
                    You can use the platform with your own translators or take
                    advantage of Smartling’s in-house translators, who can help
                    you with content localization.
                  </p>
                </Section.Inner>
              </Section>
            </div>
            <Section centerCTA>
              <Section.Header>
                <h2 className="u-align--center">Translation Made Easy.</h2>
              </Section.Header>
              <Section.Inner xl>
                <div className="o-section--home-tout l-grid l-grid--3x3 ">
                  {/* one */}

                  <div className="o-home-tout__card">
                    <div className="l-grid-area l-grid-area--a1">
                      <div className="u-padding--bottom">
                        <StaticImage
                          src={iconStar}
                          alt="Star icon"
                          width={95}
                          height={95}
                          loading="lazy"
                          placeholder="blurred"
                        />
                      </div>
                    </div>
                    <div className="l-grid-area l-grid-area--b1">
                      <h3 className="u-padding--bottom">Improve Quality</h3>
                    </div>
                    <div className="l-grid-area l-grid-area--c1 u-align--left u-spacing">
                      <p>
                        Smartling’s predictive machine learning tool provides
                        automated <strong>quality checks</strong> with the
                        content you create and translate. Get guaranteed quality
                        with Smartling Language Services.
                      </p>
                    </div>
                  </div>
                  <div className="o-home-tout__card">
                    <div className="l-grid-area l-grid-area--a1">
                      <div className="u-padding--bottom">
                        <StaticImage
                          src={iconSpeed}
                          alt="Speed icon"
                          width={95}
                          height={95}
                          loading="lazy"
                          placeholder="blurred"
                        />
                      </div>
                    </div>
                    <div className="l-grid-area l-grid-area--b1">
                      <h3 className="u-padding--bottom">Increase Speed</h3>
                    </div>
                    <div className="l-grid-area l-grid-area--c1 u-align--left u-spacing">
                      <p>
                        Automated content ingestion using Smartling
                        integrations, proxy, or API means your content is
                        instantly available for translation,{' '}
                        <strong>eliminating 90% of the manual effort.</strong>
                      </p>
                    </div>
                  </div>
                  <div className="o-home-tout__card">
                    <div className="l-grid-area l-grid-area--a1">
                      <div className="u-padding--bottom">
                        <StaticImage
                          src={iconDollar}
                          alt="Dollar sign"
                          width={95}
                          height={95}
                          loading="lazy"
                          placeholder="blurred"
                        />
                      </div>
                    </div>
                    <div className="l-grid-area l-grid-area--b1">
                      <h3 className="u-padding--bottom">Reduce Costs</h3>
                    </div>
                    <div className="l-grid-area l-grid-area--c1 u-align--left u-spacing">
                      <p>
                        Smartling technology like automation, translation
                        memory, and Neural MT combined with cost estimating and
                        translation savings reports not only help you stay
                        within budget but can{' '}
                        <strong>reduce your translation costs by 70%.</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="u-text-align--center u-padding--triple--top">
                  <a
                    role="button"
                    href="/software/translation-management-system/"
                    class="o-link u-color--primary"
                  >
                    Read more about translation management
                  </a>
                </div>
              </Section.Inner>
            </Section>

            <Section
              gradientQuaternary
              className="u-padding--quint--top u-space--quad--bottom o-section--home-testimonial"
            >
              <Section.Inner l>
                <Grid twoUp className="l-grid--align-top">
                  <Grid.Item className="u-spacing">
                    <h2 className="o-heading--xxl">The Smartling Difference</h2>
                    <p>
                      Smartling is the #1 translation solution because it is the
                      only cloud based, AI-enabled translation platform that
                      combines a Neural Machine Translation Hub and professional
                      language services empowering teams to collaborate in real
                      time to create experiences customers love.
                    </p>
                    <p>
                      Smartling customers give it a world class customer
                      satisfaction score citing an extensive list of enterprise
                      features, integrations to most major platforms, price and
                      overall ease of use.
                    </p>
                    <a
                      role="button"
                      href="/resources/101/the-best-translation-software-why-use-smartling-in-2023/"
                      class="o-link u-color--primary u-font--m"
                    >
                      Read more about translation management
                    </a>
                  </Grid.Item>
                  <Grid.Item>
                    <div className="o-section--home-testimonial--image">
                      <StaticImage
                        src={imageDifference}
                        alt="The Smartling Difference"
                        width={377}
                        height={521}
                        loading="lazy"
                        placeholder="blurred"
                      />
                    </div>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>
            <Section>
              <Section.Inner xl>
                <div className="u-text-align--center u-spacing u-space--triple--bottom">
                  <h2 className="font--l-alt">
                    Why Our Customers <br /> Love Smartling
                  </h2>
                  <a
                    role="button"
                    href="/resources/case-study/"
                    class="o-link u-color--primary"
                  >
                    Read all customer stories
                  </a>
                </div>
                <Grid fourUp className="c-collection">
                  <Grid.Item>
                    <div className="u-spacing u-customer-box">
                      <div style={{ maxWidth: `176px` }}>
                        <StaticImage
                          src={logoPinterest}
                          alt="Pinterest logo"
                          width={176}
                          height={40}
                          loading="lazy"
                          placeholder="blurred"
                        />
                      </div>
                      <p style={{ fontSize: '15px' }}>
                        "Smartling’s Translation Management System has
                        eliminated a number of daily localization tasks, once
                        required of our engineers, freeing up their time to work
                        on other projects."
                      </p>
                      <div className="u-customer-box--attr">
                        <StaticImage
                          src={imageSilvia}
                          alt="Silvia Ovideo-López"
                          width={70}
                          height={70}
                          loading="lazy"
                          placeholder="blurred"
                        />
                        <p>
                          Silvia Ovideo-López, Localization and International
                          Operations
                        </p>
                      </div>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    <div className="u-spacing u-customer-box">
                      <div style={{ maxWidth: `176px` }}>
                        <StaticImage
                          src={logoAdrollSmall}
                          alt="Adroll logo"
                          width={172}
                          height={32}
                          loading="lazy"
                          placeholder="blurred"
                        />
                      </div>
                      <p style={{ fontSize: '15px' }}>
                        "We were able to launch our new AdRoll Group website in
                        seven languages within just two weeks!"
                      </p>
                      <div className="u-customer-box--attr">
                        <StaticImage
                          src={imageGavin}
                          alt="Gavin Flood"
                          width={70}
                          height={70}
                          loading="lazy"
                          placeholder="blurred"
                        />
                        <p>Gavin Flood, Senior Director of Marketing</p>
                      </div>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    <div className="u-spacing u-customer-box">
                      <div style={{ maxWidth: `176px` }}>
                        <StaticImage
                          src={logoYext}
                          alt="Yexy logo"
                          width={37}
                          height={40}
                          loading="lazy"
                          placeholder="blurred"
                        />
                      </div>
                      <p style={{ fontSize: '15px' }}>
                        "At the end of the day, Smartling’s technology is
                        incredibly reliable, and the customer experience is
                        fantastic."
                      </p>
                      <div className="u-customer-box--attr">
                        <StaticImage
                          src={imageJessica}
                          alt="Jessica Bierenz"
                          width={70}
                          C
                          height={70}
                          loading="lazy"
                          placeholder="blurred"
                        />
                        <p>
                          Jessica Birenz, Director of Planning and Project
                          Management
                        </p>
                      </div>
                    </div>
                  </Grid.Item>

                  <Grid.Item>
                    <div className="u-spacing u-background--primary u-customer-box">
                      <p className="u-color-white u-font-weight--bold">
                        Smartling puts its customers at the forefront of its
                        business strategy. Learn how our customers leverage
                        Smartling to realize translation and localization
                        efficiency gains
                      </p>
                      <Button outlineWhite url="/resources/case-study/">
                        See more
                      </Button>
                    </div>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>
            <Section>
              <Section.Inner className="o-section-home--awards" xl>
                <Grid threeUp className="l-grid--align-center">
                  <Grid.Item className="u-spacing">
                    <div className="u-star-column">
                      <StaticImage
                        src={imageStars}
                        alt="4.5 stars"
                        width={177}
                        height={29}
                        loading="lazy"
                        placeholder="blurred"
                      />
                      <p>4.5 out of 5 stars on G2</p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="u-spacing">
                    <div
                      className="c-hero__badges"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        gap: '10px',
                      }}
                    >
                      <StaticImage
                        src={g2bestSoftware} 
                        alt="G2 Best Software 2024 Badge"
                        loading="lazy"
                        className="o-badge"
                        placeholder="blurred"
                      />
                      <StaticImage
                        src={g2leaderEnterprise} 
                        alt="G2 Leader Enterprise 2024 Badge"
                        loading="lazy"
                        className="o-badge"
                        placeholder="blurred"
                      />
                      <StaticImage
                        src={g2LeaderMidmkt} 
                        alt="G2 Leader Midmarket 2024 Badge"
                        loading="lazy"
                        className="o-badge"
                        placeholder="blurred"
                      />
                      <StaticImage
                        src={g2leaderEnterpriseEmea}
                        alt="G2 Leader Enterprise EMEA 2024 Badge"
                        placeholder="blurred"
                        loading="lazy"
                        className="o-badge"
                      />
                    </div>
                    <p className="u-align--center u-padding--half--left u-padding--half--right">
                      Voted #1 Translation Management System by Customers on G2 for 20 Consecutive Quarters
                    </p>
                  </Grid.Item>
                  <Grid.Item className="u-spacing u-padding--half--top">
                    <div className="u-star-column">
                      <StaticImage
                        src={imageStars8of10}
                        alt="8 of 10 stars"
                        width={188}
                        height={27}
                        loading="lazy"
                        placeholder="blurred"
                      />
                      <p>8 out of 10 stars on Trustradius</p>
                    </div>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>
            <Section className="u-frontpage-grey">
              <Section.Inner s>
                <div className="u-spacing u-padding--quint--bottom">
                  <div className="l-container--xs u-text-align--center u-spacing">
                    <h2>Translation For Numerous Content Types</h2>
                    <p className="u-font--m">
                      Localize your content across devices and platforms to give
                      your customers what they want.
                    </p>
                  </div>
                  <AccordionSection>
                    <Accordion
                      defaultOpen
                      title="Websites & ECommerce"
                      content={
                        <div className="u-frontpage--accordion-content">
                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Case studies</li>
                            <li>Product pages</li>
                            <li>Landing pages</li>
                            <li>Blogs</li>
                            <li>FAQs</li>
                          </ul>

                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Payment portals</li>
                            <li>Product tours</li>
                            <li>Learning centers</li>
                            <li>Communities</li>
                            <li>404 pages</li>
                          </ul>

                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Chat</li>
                            <li>Mobile</li>
                            <li>Resource hubs</li>
                            <li>Microcontent</li>
                            <li>Product demos</li>
                          </ul>

                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>
                              And more<super>*</super>
                            </li>
                          </ul>
                        </div>
                      }
                    />
                    <Accordion
                      title="Marketing & Media"
                      content={
                        <div className="u-frontpage--accordion-content">
                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Digital ads</li>
                            <li>Social media</li>
                            <li>Media kits</li>
                            <li>Social stories</li>
                            <li>SMS</li>
                          </ul>
                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Content Marketing Translation</li>
                            <li>Press Release Translation</li>
                            <li>White Paper Translation</li>
                            <li>Subtitles</li>
                            <li>Video Transcription</li>
                          </ul>
                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Advertising Transcreation</li>
                          </ul>
                        </div>
                      }
                    />
                    <Accordion
                      title="Product & CX"
                      content={
                        <div className="u-frontpage--accordion-content">
                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Software Localization</li>
                            <li>Technical Documentation</li>
                            <li>Chatbots</li>
                            <li>User Support/FAQ Localization</li>
                            <li>Real time Customer Support Translation</li>
                          </ul>
                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Mobile App localization</li>
                          </ul>
                        </div>
                      }
                    />
                    <Accordion
                      title="Other"
                      content={
                        <div className="u-frontpage--accordion-content">
                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Clinical Trial document translation</li>
                            <li>Legal Translation</li>
                            <li>Regulatory Submission Translation</li>
                            <li>Quizzes</li>
                            <li>QBRs</li>
                          </ul>
                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>RFP</li>
                            <li>Calculators</li>
                            <li>Virtual Reality</li>
                            <li>Games</li>
                            <li>Subtitles</li>
                            <li>Audio</li>
                            <li>Video</li>
                          </ul>
                          <ul className="o-bullet-list o-bullet-list--less-pad u-full-width">
                            <li>Conference material translation</li>
                            <li>Corporate communication Translation</li>
                            <li>eLearning Localization</li>
                            <li>Compliance Training</li>
                            <li>HR and Training Material</li>
                          </ul>
                        </div>
                      }
                    />
                  </AccordionSection>
                  <p className="u-space--tripple--top u-text-align--center u-font-weight--bold">
                    *Smartling can work with companies of different sizes and
                    industries.
                    <br />
                    We will find you a personalized solution, depending on your
                    needs.
                  </p>
                </div>
              </Section.Inner>
            </Section>
            <Section>
              <Section.Header>
                <h3 className="u-padding--half-left u-padding--half-right u-space--bottom">
                  Smartling Integrates With
                </h3>
              </Section.Header>
              <Section.Inner xl>
                <div className="l-grid l-grid--mosaic">
                  <div>
                    <StaticImage
                      src={logoIllustrator}
                      alt="Illustrator Logo"
                      width={68}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoPhotoshop}
                      alt="Photoshop Logo"
                      width={94}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoFigma}
                      alt="'Figma'"
                      width={70}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoContentful}
                      alt="'Contentful'"
                      width={127}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoSfSvcCloud}
                      alt="'Salesforce Service Cloud'"
                      width={100}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoZenDesk}
                      alt="'Zendesk'"
                      width={121}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoContentStack}
                      alt="'ContentStack'"
                      // width={130}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoMarketo}
                      alt="'Marketo'"
                      width={126}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoDrupal}
                      alt="'Drupal'"
                      width={119}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoSiteCore}
                      alt="'Site Core'"
                      width={127}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoSanity}
                      alt="'Sanity'"
                      width={127}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoWordpress}
                      alt="'Wordpress'"
                      width={86}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoHubSpot}
                      alt="'HubSpot'"
                      width={78}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoOracleEloqua}
                      alt="'Oracle Eloqua'"
                      width={100}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoSfCommCloud}
                      alt="'Salesforce Community Cloud'"
                      width={76}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoSapHybris}
                      alt="'SAP Hybris'"
                      width={113}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoSketch}
                      alt="'Sketch'"
                      width={117}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src={logoGoogleDrive}
                      alt="'Google Drive'"
                      width={102}
                      height={28}
                      loading="lazy"
                      placeholder="blurred"
                    />
                  </div>
                </div>
                <div className="u-padding--double--top u-text-align--center">
                  <a
                    role="button"
                    href="/software/"
                    class="o-link u-color--primary"
                  >
                    See all integrations
                  </a>
                </div>
              </Section.Inner>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
