import React, { useState, useRef, useEffect } from 'react'
import Section from 'atoms/section'
import Slider from 'react-slick'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'

function SlickSlider({ children, settings }) {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(1)

  const slider1Ref = useRef(null)
  const slider2Ref = useRef(null)

  useEffect(() => {
    setNav1(slider1Ref.current)
    setNav2(slider2Ref.current)
  }, [])

  const images = children

  const settingsImage = {
    adaptiveHeight: true,
    infinite: true,
    asNavFor: nav2,
    ref: slider1Ref,
    afterChange: (currentSlide) => setCurrentSlide(currentSlide + 1),
    slidesToShow: 7,
    slidesToScroll: 3,
    centerPadding: '12px',
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  }

  const settingsCaption = {
    adaptiveHeight: true,
    infinite: true,
    fade: true,
    arrows: false,
    asNavFor: nav1,
    ref: slider2Ref,
  }

  return (
    <Section className="c-slick-slider">
      <Section.Inner xl>
        <Slider {...settingsImage}>{images}</Slider>
      </Section.Inner>
    </Section>
  )
}

export default SlickSlider
