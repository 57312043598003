import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Button from 'atoms/button'
import PardotForm from 'components/forms/pardot'
import Grid from 'atoms/grid'
import Block from 'molecules/block'
import BlockPerson from 'molecules/block-person'
import Section from 'atoms/section'
import Card from 'molecules/card'
import { Link } from 'react-scroll'
import ShareBar from 'molecules/share-bar'
import BlockPost from 'molecules/block-post'
import PardotResponsive from 'organisms/pardot-responsive'
import { getEventPath } from '../routes'
import { GatsbyImage } from 'gatsby-plugin-image'
import videoIcon from '../images/icons/icon_video-light-ring.png'
import HubSpotForm from 'components/forms/HubSpotForm'

import MetaDataImage from '../images/meta-image.png'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'
import Layout from 'layouts'

const moment = require('../../static/vendor/moment.min.js')
var speakerHeadline = 'Speakers' // Default text for Speaker Headline
var featuredSpeakerHeadline = 'Featured Speakers' // Default text for Featured Speaker Headline
var callForSpeakersHeading = 'Call For Speakers' // Default text for Call for Speaker Headline
var rsvpHeadline = 'Details' // Default text for RSVP Headline
var locationHeadline = 'Location' // Default text for Location Headline
var aboutHeadline = 'About' // Default text for About Headline
var agendaHeadline = 'Agenda' // Default text for Agenda Headline

class EventTemplate extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props;
    const hubspotFormReference = data.page.hubspotFormReference || {};
    const { portalid, formid, campaignId } = hubspotFormReference;

    return (
      <Layout>
        <div
          className={
            'l-page--event ' +
            (data.page.isWebinar ? `c-page__event--webinar` : `c-page__event`)
          }
        >
          <Metadata
            title={data.page.metadataTitle || `${data.page.title} - Smartling`}
            description={
              data.page.metadataDescription || data.page.shortDescription
            }
            keywords={data.page.metadataKeywords?.join(`, `)}
            image={data.page.heroImage?.file?.url || MetaDataImage}
          />

          <div className="l-page--event--hero">
            <Hero
              backgroundPrimary
              className={
                'c-hero__event ' +
                (data.page.heroImage?.file?.url
                  ? `c-hero__event--with-image`
                  : `c-hero__event--no-image`)
              }
              title={data.page.name}
              kicker={data.page.eventType && data.page.eventType[0]}
            >
              <Hero.Description>
                {data.page.startDate && (
                  <span className="o-event-date">
                    {data.page.startDate &&
                    moment
                      .parseZone(data.page.startDate)
                      .format('dddd, MMMM D | h:mmA')} {data.page.eventTimezone && data.page.eventTimezone}
                    {
                      data.page.endDate && data.page.eventTimezone 
                      ?
                    ` – ${
                      moment
                        .parseZone(data.page.endDate)                        
                        .format('dddd, MMMM D | h:mmA')
                    } ${data.page.eventTimezone}`                    
                    :
                    null
                    } 
                    
                    {/* {data.page.startDate &&
                      moment
                        .parseZone(data.page.startDate)
                        .utcOffset('-05:00')
                        .format('dddd, MMMM D | h:mmA') + ' est'} */}                    
                    {/*{data.page.endDate &&
                      ` – ${
                        moment
                          .parseZone(data.page.endDate)
                          .utcOffset('-05:00')
                          .format('dddd, MMMM D | h:mmA') + ' est'
                      }`}*/}
                    {data.page.eventLocation?.city &&
                      `, ${data.page.eventLocation.city}`}
                    {data.page.eventLocation?.state &&
                      ` ${data.page.eventLocation.state}`}
                  </span>
                )}
                {data.page.shortDescription && (
                  <span>{data.page.shortDescription}</span>
                )}
                {/* <div>
                  <ShareBar
                    uri={getEventPath(
                      data.page.slug,
                      data.page.event_category?.name
                    )}
                    title={data.page.name}
                    reverseColor
                  ></ShareBar>
                </div> */}
              </Hero.Description>
              <Hero.Buttons>
                {data.page.buttonUrl && (
                  <Button
                    url={data.page.buttonUrl}
                    target={data.page.openInNewWindow ? `_blank` : `_self`}
                  >
                    {data.page.buttonText ? data.page.buttonText : `Learn More`}
                  </Button>
                )}
              </Hero.Buttons>
              {data.page.backgroundImage?.file?.url && (
                <Hero.Image>
                  <GatsbyImage
                    image={data.page.backgroundImage.gatsbyImageData}
                    alt={data.page.backgroundImage.title}
                    objectFit="cover"
                  />
                </Hero.Image>
              )}
            </Hero>
            {data.page.registrationFormLocation && data.page.hubspotFormReference && (
              <div className="l-event--pardot">
                <h3 className="c-ppc__hero--pardot__heading">Register here</h3>
                <div id="hubspot-form-container" className="hubspot-form-container">
                  <HubSpotForm portalId={portalid} formId={formid} campaignId={campaignId || ''} />
                </div>
              </div>
            )}
          </div>

          {!data.page.isWebinar && !data.page.hideMenu && (
            <div className="c-event-nav o-section--inner l-wrap l-container--xl">
              <nav className="c-filter-nav">
                <Link
                  activeclassName="is-active"
                  spy={true}
                  duration={500}
                  smooth={true}
                  offset={-100}
                  className="c-filter-nav__link"
                  to="about"
                  href="#about"
                >
                  {data.page.aboutTitle ? data.page.aboutTitle : aboutHeadline}
                </Link>
                {data.page.agendaItem && (
                  <Link
                    activeclassName="is-active"
                    spy={true}
                    duration={500}
                    smooth={true}
                    className="c-filter-nav__link"
                    to="agenda"
                    href="#agenda"
                  >
                    {data.page.agendaTitle
                      ? data.page.agendaTitle
                      : agendaHeadline}
                  </Link>
                )}
                <Link
                  activeclassName="is-active"
                  spy={true}
                  duration={500}
                  smooth={true}
                  className="c-filter-nav__link"
                  to="location"
                  href="#location"
                >
                  {data.page.eventLocationTitle
                    ? data.page.eventLocationTitle
                    : locationHeadline}
                </Link>
                <Link
                  activeclassName="is-active"
                  spy={true}
                  duration={500}
                  smooth={true}
                  offset={-100}
                  className="c-filter-nav__link"
                  to="rsvp"
                  href="#rsvp"
                >
                </Link>
                {data.page.speakers && (
                  <Link
                    activeclassName="is-active"
                    spy={true}
                    duration={500}
                    smooth={true}
                    className="c-filter-nav__link"
                    to="speakers"
                    href="#speakers"
                  >
                    {data.page.speakerHeading
                      ? data.page.speakerHeading
                      : speakerHeadline}
                  </Link>
                )}
              </nav>
            </div>
          )}

          <article
            className={`l-article l-wrap l-container--xl u-spacing--sections ${
              data.page?.hubspotFormReference && 'l-event--about__has-form'
            }`}
          >
            <div className="l-event--article">
              <div id="about" className="l-event--about">
                {data.page.aboutBlock1 && (
                  <div className="o-text u-spacing u-space--triple--bottom">
                    <DangerousHTML>
                      {data.page.aboutBlock1.html.text}
                    </DangerousHTML>
                  </div>
                )}
                {data.page.aboutBlock2 && (
                  <div className="o-text u-spacing u-padding--bottom l-event__about-two">
                    <DangerousHTML>
                      {data.page.aboutBlock2.html.text}
                    </DangerousHTML>
                  </div>
                )}
              </div>
              {data.page.registrationFormLocation === false &&
                data.page.hubspotFormReference && (
                  <div className="l-event--pardot">
                    <h3 className="c-ppc__hero--pardot__heading">
                      Register here
                    </h3>
                    <div id="hubspot-form-container" className="hubspot-form-container">
                      <HubSpotForm portalId={portalid} formId={formid} campaignId={campaignId || ''} />
                    </div>
                  </div>
                )}
            </div>
            {data.page.aboutButtons && (
              <div className="u-center-block u-text-align--center c-section-button">
                {data.page.aboutButtons.map((button) => {
                  return (
                    <Button
                      url={button.buttonLink}
                      target={button.newWindow ? `_blank` : `_self`}
                    >
                      {button.buttonText ? button.buttonText : `Learn More`}
                    </Button>
                  )
                })}
              </div>
            )}
            {!data.page.aboutButtons &&
              data.page.buttonUrl &&
              !data.page.isWebinar && (
                <div className="u-center-block u-text-align--center">
                  <Button
                    url={
                      data.page.aboutButtonUrl
                        ? data.page.aboutButtonUrl
                        : data.page.buttonUrl
                    }
                    target={data.page.openInNewWindow ? `_blank` : `_self`}
                  >
                    {data.page.aboutButtonText
                      ? data.page.aboutButtonText
                      : data.page.buttonText
                      ? data.page.buttonText
                      : `Learn More`}
                  </Button>
                </div>
              )}
            {data.page.agendaItem && (
              <div id="agenda">
                <Section gradientTertiary curveTop className="c-agenda">
                  <Section.Header>
                    <Block
                      title={
                        data.page.agendaTitle
                          ? data.page.agendaTitle
                          : agendaHeadline
                      }
                    />
                    {data.page.agendaSubtitle && (
                      <div className="c-block__description u-spacing">
                        {data.page.agendaSubtitle
                          ? data.page.agendaSubtitle
                          : ''}
                      </div>
                    )}
                  </Section.Header>
                  <Section.Inner l>
                    <div className="c-agenda__content u-spacing">
                      {data.page.agendaItem.map((agenda, index) => {
                        return (
                          <div
                            className={
                              'c-agenda__item ' +
                              (agenda.hasConcurrentSession
                                ? 'c-agenda__item--concurrent'
                                : '')
                            }
                          >
                            {agenda.sessionDescription && (
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id={'c-agenda-toggle--' + index}
                                />
                                <label
                                  className="c-agenda-toggle"
                                  htmlFor={'c-agenda-toggle--' + index}
                                >
                                  View Session Description
                                </label>
                              </React.Fragment>
                            )}

                            <p className="c-agenda__item--time">
                              {agenda.time}
                            </p>

                            <div className="c-agenda__item-copy">
                              <h4 className="c-agenda__item--title">
                                {agenda.sessionTitle}
                              </h4>
                              <div className="c-agenda__item--desc">
                                {agenda.speaker && (
                                  <div className="c-agenda__item--speaker">
                                    {agenda.speaker}{' '}
                                  </div>
                                )}

                                {agenda.sessionDescription && (
                                  <div className="c-agenda__item--text u-spacing--half">
                                    <DangerousHTML>
                                      {agenda.sessionDescription.html.text}
                                    </DangerousHTML>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </Section.Inner>
                </Section>
              </div>
            )}
            {data.page.agendaItem && data.page.agendaButtons && (
              <div className="u-center-block u-text-align--center c-section-button">
                {data.page.agendaButtons.map((button) => {
                  return (
                    <Button
                      url={button.buttonLink}
                      target={button.newWindow ? `_blank` : `_self`}
                    >
                      {button.buttonText ? button.buttonText : `Learn More`}
                    </Button>
                  )
                })}
              </div>
            )}
            {data.page.eventLocation && (
              <div id="location">
                <SectionCta
                  title="When & Where"
                  gradientPrimary
                  curveTop
                  curveBottom
                  className="c-event-location"
                >
                  {data.page.eventLocation.locationImage && (
                    <SectionCta.ImageLarge>
                      <GatsbyImage
                        image={
                          data.page.eventLocation.locationImage.gatsbyImageData
                        }
                        alt="Location"
                      />
                    </SectionCta.ImageLarge>
                  )}
                  <SectionCta.Description>
                    {data.page.startDate && (
                      <div className="u-font--s">
                        <div className="o-event-date">
                          <span>
                            {moment
                              .parseZone(data.page.startDate)
                              .format('MMMM Do YYYY, h:mm a')}
                          </span>
                          {data.page.endDate && (
                            <span>
                              &nbsp; – &nbsp;
                              {moment
                                .parseZone(data.page.endDate)
                                .format('MMMM Do YYYY, h:mm a')}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="c-event-location__text u-spacing">
                      {data.page.eventLocation.locationName &&
                        data.page.eventLocation.locationName}
                      <div className="o-address">
                        {data.page.eventLocation.addressLine1 &&
                          data.page.eventLocation.addressLine1}
                        {data.page.eventLocation.addressLine2 &&
                          `, ${data.page.eventLocation.addressLine2}`}
                        {data.page.eventLocation.city &&
                          `, ${data.page.eventLocation.city}`}
                        {data.page.eventLocation.state &&
                          `, ${data.page.eventLocation.state}`}
                        {data.page.eventLocation.zipcode &&
                          ` ${data.page.eventLocation.zipcode}`}
                      </div>
                      {data.page.eventLocation.directionsButtonUrl && (
                        <Button
                          url={data.page.eventLocation.directionsButtonUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Get Directions
                        </Button>
                      )}
                    </div>
                  </SectionCta.Description>
                </SectionCta>
              </div>
            )}
            {/* {data.page.callForSpeakers && (
              <div id="speakers">
                <SectionCta
                  title={
                    data.page.callForSpeakersHeading
                      ? data.page.callForSpeakersHeading
                      : callForSpeakersHeading
                  }
                  gradientPrimary
                  centerCTA
                  curveTop
                  curveBottom
                >
                  <SectionCta.Image>
                    <GatsbyImage
                      image={
                        data.page.callForSpeakersImage?.file?.url
                          ? data.page.callForSpeakersImage.gatsbyImageData
                          : ''
                      }
                      alt={
                        data.page.callForSpeakersHeading ||
                        callForSpeakersHeading
                      }
                    />
                  </SectionCta.Image>
                  <SectionCta.Description>
                    <div className="u-spacing">
                      {data.page.callForSpeakers && (
                        <DangerousHTML>
                          {data.page.callForSpeakers.html.text}
                        </DangerousHTML>
                      )}
                    </div>
                  </SectionCta.Description>
                  <SectionCta.Buttons>
                    {data.page.callForSpeakersButtons.map((button) => {
                      return (
                        <Button
                          url={button.buttonLink}
                          target={button.newWindow ? `_blank` : `_self`}
                        >
                          {button.buttonText ? button.buttonText : `Learn More`}
                        </Button>
                      )
                    })}
                  </SectionCta.Buttons>
                </SectionCta>
              </div>
            )} */}
            {data.page.speakers && (
              <div
                id={
                  !data.page.callForSpeakers && !data.page.featuredSpeakers
                    ? 'speakers'
                    : 'main-speakers'
                }
              >
                <Section
                  className={
                    !data.page.callForSpeakers || !data.page.featuredSpeakers
                      ? 'u-padding--top c-speakers'
                      : 'c-speakers'
                  }
                >
                  <Section.Header>
                    <Block
                      title={
                        data.page.speakerHeading
                          ? data.page.speakerHeading
                          : speakerHeadline
                      }
                    />
                  </Section.Header>
                  <Section.Inner l>
                    {data.page.speakers.map((speaker) => {
                      return <BlockPerson {...speaker} toggleAbout />
                    })}
                  </Section.Inner>
                  {data.page.speakersButtons && (
                    <div className="u-center-block u-text-align--center">
                      {data.page.speakersButtons.map((button) => {
                        return (
                          <Button
                            url={button.buttonLink}
                            target={button.newWindow ? `_blank` : `_self`}
                          >
                            {button.buttonText
                              ? button.buttonText
                              : `Learn More`}
                          </Button>
                        )
                      })}
                    </div>
                  )}
                </Section>
              </div>
            )}

            {data.pastEvents.edges && (
              <div id="events">
                <Section>
                  <Section.Inner
                    xl
                    className="u-text-align--center u-spacing--triple"
                  >
                    <div className="l-grid__header">
                      <h2 className="u-margin-x--auto">Watch On-Demand</h2>
                    </div>
                    <Grid threeUp className="c-events">
                      {data.pastEvents.edges.map(({ node }) => (
                        <Grid.Item>
                          <BlockPost {...node} />
                        </Grid.Item>
                      ))}
                      {/* <Grid.Item>
                        <div
                          className="c-block c-block__space-between u-background-color--primary"
                          style={{
                            borderRadius: 15,
                            height: '100%',
                            padding: '70px 50px',
                          }}
                        >
                          <img
                            loading="lazy"
                            style={{ width: '70%' }}
                            src={videoIcon}
                            alt="Video"
                          />
                          <div className="c-block">
                            <h3 className=" u-color--white">
                              Looking for more?
                            </h3>
                            <div className="u-color--white">
                              Access on-demand webinars, events and workshops
                            </div>
                          </div>
                          <div className="c-hero__button-container">
                            <a
                              className="o-button o-button--white"
                              href="/resources/webinar/"
                              role="button"
                            >
                              View All
                            </a>
                          </div>
                        </div>
                      </Grid.Item> */}
                    </Grid>
                    <Button
                      url="/resources/webinar/"
                      className="u-margin-x--auto u-margin-y--double"
                    >
                      View All
                    </Button>
                  </Section.Inner>
                </Section>
              </div>
            )}
          </article>
        </div>
      </Layout>
    )
  }
}

export default EventTemplate

export const pageQuery = graphql`
  query EventTemplateQuery($slug: String!) {
    page: contentfulEvent(slug: { eq: $slug }) {
      name
      shortDescription
      slug
      eventType
      isWebinar
      startDate
      endDate
      eventTimezone
      hideMenu
      event_category {
        key
        name
      }
      hubspotFormReference {
        portalid
        formid
        campaignId
      }
      eventLocationTitle
      eventLocation {
        locationName
        locationImage {
          ...ContentfulImageFragment
          gatsbyImageData(
            width: 812
            height: 514
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
        }
        addressLine1
        addressLine2
        city
        state
        zipcode
        directionsButtonUrl
      }
      aboutTitle
      aboutBlock1 {
        html: childMarkdownRemark {
          text: html
        }
      }
      aboutBlock2 {
        html: childMarkdownRemark {
          text: html
        }
      }
      heroImage {
        ...ContentfulImageFragment
        gatsbyImageData(
          width: 619
          height: 448
          formats: [WEBP, AUTO]
          placeholder: BLURRED
        )
      }
      backgroundImage {
        ...ContentfulImageFragment
        gatsbyImageData(
          width: 619
          height: 448
          formats: [WEBP, AUTO]
          placeholder: BLURRED
        )
      }
      buttonText
      buttonUrl
      aboutButtons {
        buttonText
        buttonLink
        newWindow
      }
      agendaButtons {
        buttonText
        buttonLink
        newWindow
      }
      # pardotForm {
      #   pardotFormLink
      #   formHeight
      # }
      # featuredSpeakersButtons {
      #   buttonText
      #   buttonLink
      #   newWindow
      # }
      speakersButtons {
        buttonText
        buttonLink
        newWindow
      }
      openInNewWindow
      agendaTitle
      agendaSubtitle
      agendaItem {
        sessionTitle
        sessionDescription {
          html: childMarkdownRemark {
            text: html
          }
        }
        speaker
        time
        hasConcurrentSession
      }
      speakerHeading
      speakers {
        slug
        name
        headshot {
          ...ContentfulImageFragment
        }
        positionTitle
        company
        companyLink
        about {
          html: childMarkdownRemark {
            text: html
          }
        }
      }
      registerForm {
        pardotFormLink
        formHeight
      }
      registrationFormLocation
      metadataTitle
      metadataDescription
      metadataKeywords
    }
    pastEvents: allContentfulResource(
      limit: 3
      sort: { fields: [publishDate, title], order: DESC }
      filter: { tags: { elemMatch: { key: { eq: "webinar" } } } }
    ) {
      ...ContentfulResourceFragment
    }
  }
`
