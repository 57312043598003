import React from 'react'
import { graphql } from 'gatsby'
import { getLearningHubPath } from '../routes'
import { DangerousHTML } from 'atoms/contentful/html'
import Block from 'molecules/block'

const BlockLearningHubs = ({ title, shortDescription, path }) => {
  return (
    <React.Fragment>
      <div className="c-block-post--tile">
        <Block title={title}>
          {shortDescription && (
            <DangerousHTML>{shortDescription}</DangerousHTML>
          )}
        </Block>
        <a className="o-link" href={getLearningHubPath(path)}>
          More
        </a>
      </div>
    </React.Fragment>
  )
}

export default BlockLearningHubs

export const query = graphql`
  fragment ContentfulLearningHubPageFragment on ContentfulLearningHubPageConnection {
    totalCount
    edges {
      node {
        title
        shortDescription
        path
      }
    }
  }
`
